import { AxiosRequestConfig } from 'axios';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

type RequestResponse = {
  status: number;
  data: any;
};

const useAuthRequest = () => {
  const navigate = useNavigate();

  const sendRequest = async <T,>(url: string, method: AxiosRequestConfig['method'], data?: T): Promise<RequestResponse> => {
    try {
      const token = localStorage.getItem('authToken');
      if (!token) {
        throw new Error('Nicht authentifiziert');
      }

      const response = await axios({
        method,
        url,
        data,
        headers: { 'Authorization': `Bearer ${token}` },
      });

      return response;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.status === 401) {
        localStorage.removeItem('authToken');
        navigate('/login');
      }
      throw error;
    }
  };

  return sendRequest;
};

export default useAuthRequest;
