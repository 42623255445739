import React, { useCallback, useEffect, useState } from "react";
import { ImageIcon, TrashIcon, DownloadIcon, ExclamationTriangleIcon } from "@radix-ui/react-icons";
import { Badge } from "src/components/ui/badge";
import { Button } from "src/components/ui/button";
import { Card } from "src/components/ui/card";
import { CustomImageFile } from "../../helper/CustomProductContext";
import { Input } from "src/components/ui/input";
import { Label } from "src/components/ui/label";
import { useCustomProducts } from "../../helper/CustomProductContext";
import { Textarea } from "src/components/ui/textarea";
import {
	Select,
	SelectContent,
	SelectGroup,
	SelectItem,
	SelectLabel,
	SelectTrigger,
	SelectValue,
} from "src/components/ui/select";

interface UpdateCustomProductSheetProps {
	passthroughArcticleId: number;
	onClose: () => void;
}

const UpdateCustomProductSheet: React.FC<UpdateCustomProductSheetProps> = ({ passthroughArcticleId, onClose }) => {
	const {
		fetchProductGroups,
		productGroups,
		fetchProductById,
		updateProduct,
		customProduct,
		deleteImage,
		deleteProduct,
	} = useCustomProducts();

	const [initialValues, setInitialValues] = useState<any>({});
	const [articleId, setArticleId] = useState<number>(0);
	const [description, setDescription] = useState<string>("");
	const [unit, setUnit] = useState<string>("");
	const [searchTerm, setSearchTerm] = useState<string>("");
	const [additionalInfo, setAdditionalInfo] = useState<string>("");
	const [price, setPrice] = useState<number>(0);
	const [barcode, setBarcode] = useState<string>("");
	const [weight, setWeight] = useState<number>(0);
	const [weightUnit, setWeightUnit] = useState<string>("");
	const [basePriceUnit, setBasePriceUnit] = useState<string>("");
	const [available, setAvailable] = useState<number>(0);
	const [productGroup, setProductGroup] = useState<string>("");
	const [imageFiles, setImageFiles] = useState<CustomImageFile[]>([]);
	const [selectedImages, setSelectedImages] = useState<File[]>([]);
	const [isDragging, setIsDragging] = useState<boolean>(false);
	const [active, setActive] = useState<boolean>(false);
	const [showPrice, setShowPrice] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		fetchProductById(passthroughArcticleId);
		fetchProductGroups();
	}, [passthroughArcticleId]);

	useEffect(() => {
		if (customProduct) {
			setInitialValues({
				articleId: customProduct.articleId,
				description: customProduct.description || "",
				unit: customProduct.unit || "",
				searchTerm: customProduct.searchTerm || "",
				additionalInfo: customProduct.additionalInfo || "",
				price: customProduct.price || 0,
				barcode: customProduct.barcode || "",
				weight: customProduct.weight || 0,
				weightUnit: customProduct.weightUnit || "",
				basePriceUnit: customProduct.basePriceUnit || "",
				available: customProduct.available || 0,
				productGroup: customProduct.productGroup || "",
				active: customProduct.active || false,
				showPrice: customProduct.showPrice || false,
			});

			setArticleId(customProduct.articleId);
			setDescription(customProduct.description || "");
			setUnit(customProduct.unit || "");
			setSearchTerm(customProduct.searchTerm || "");
			setAdditionalInfo(customProduct.additionalInfo || "");
			setPrice(customProduct.price || 0);
			setBarcode(customProduct.barcode || "");
			setWeight(customProduct.weight || 0);
			setWeightUnit(customProduct.weightUnit || "");
			setBasePriceUnit(customProduct.basePriceUnit || "");
			setAvailable(customProduct.available || 0);
			setProductGroup(customProduct.productGroup || "");
			setImageFiles(customProduct.images || []);
			setActive(!!customProduct.active);
			setShowPrice(!!customProduct.showPrice);
		}
	}, [customProduct]);

	const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
		const files = Array.from(event.target.files || []);
		setSelectedImages([...selectedImages, ...files]);
	};

	const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
		event.preventDefault();
		const files = Array.from(event.dataTransfer.files);
		setSelectedImages([...selectedImages, ...files]);
		setIsDragging(false);
	};

	const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
		event.preventDefault();
	};

	const handleDragEnter = () => {
		setIsDragging(true);
	};

	const handleDragLeave = () => {
		setIsDragging(false);
	};

	const removeImage = (indexToRemove: number) => {
		setSelectedImages((prevImages) => prevImages.filter((_, index) => index !== indexToRemove));
	};

	const handleUpdateProduct = async () => {
		setIsLoading(true);
		try {
			const updatedFields: any = {};

			if (description !== initialValues.description) updatedFields.description = description;
			if (unit !== initialValues.unit) updatedFields.unit = unit;
			if (searchTerm !== initialValues.searchTerm) updatedFields.searchTerm = searchTerm;
			if (additionalInfo !== initialValues.additionalInfo) updatedFields.additionalInfo = additionalInfo;
			if (price !== initialValues.price) updatedFields.price = price;
			if (barcode !== initialValues.barcode) updatedFields.barcode = barcode;
			if (weight !== initialValues.weight) updatedFields.weight = weight;
			if (weightUnit !== initialValues.weightUnit) updatedFields.weightUnit = weightUnit;
			if (basePriceUnit !== initialValues.basePriceUnit) updatedFields.basePriceUnit = basePriceUnit;
			if (available !== initialValues.available) updatedFields.available = available;
			if (productGroup !== initialValues.productGroup) updatedFields.productGroup = productGroup;
			if (active !== initialValues.active) updatedFields.active = active;
			if (showPrice !== initialValues.showPrice) updatedFields.showPrice = showPrice;
			if (selectedImages.length > 0) updatedFields.images = selectedImages;

			if (Object.keys(updatedFields).length === 0) {
				console.log("Keine Änderungen zum Aktualisieren.");
				return;
			}

			await updateProduct(articleId, updatedFields);
			console.log("BEARBEITETE FELDER: ", updatedFields);
			onClose();
		} catch (error) {
			console.error("Fehler beim Aktualisieren des Produkts:", error);
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<>
			<div className="pb-2 pt-8">
				<div>
					<section className="flex items-start justify-start gap-4 p-0 m-0">
						<div className="grid grid-cols-1 w-3/5 gap-4">
							{/* Artikelinformationen */}
							<Card className="w-full h-full gap-4 py-8 pl-16 pr-16 items-start justify-start flex-grow">
								<h3 className="mb-2 text-lg font-medium">Artikelinformationen</h3>
								<div className="flex items-center justify-center gap-4">
									<span className="w-full">
										<label htmlFor="articleId">Art.-Nr.</label>
										<div className="flex flex-col items-start justify-start gap-2">
											<Input
												id="articleId"
												type="text"
												disabled
												value={articleId.toString()}
												onChange={(e) => setArticleId(Number(e.target.value))}
											></Input>
										</div>
									</span>
									<span className="w-full">
										<label htmlFor="barcode">EAN</label>
										<div className="flex flex-col items-start justify-start gap-2">
											<Input
												id="barcode"
												type="text"
												value={barcode}
												onChange={(e) => setBarcode(e.target.value)}
											></Input>
										</div>
									</span>
									<span className="w-full">
										<label htmlFor="quantity">Bestand</label>
										<div className="flex flex-col items-start justify-start gap-2">
											<Input
												id="quantity"
												type="text"
												value={available === 0 ? "" : available.toString()}
												onChange={(e) => {
													const numericValue = e.target.value.replace(/\D/g, "");
													setAvailable(Number(numericValue));
												}}
											></Input>
										</div>
									</span>
								</div>
							</Card>

							{/* Allgemeine Informationen */}
							<Card className="flex flex-col w-full h-full gap-4 py-8 pl-16 pr-16 items-start justify-start">
								<h3 className="mb-2 text-lg font-medium">Allgemeine Informationen</h3>
								<div className="w-full flex items-center justify-center gap-4">
									<span className="w-full">
										<label htmlFor="searchTerm">Produktname</label>
										<div className="flex flex-col items-start justify-start gap-2">
											<Input
												id="searchTerm"
												type="text"
												value={searchTerm}
												onChange={(e) => setSearchTerm(e.target.value)}
											></Input>
										</div>
									</span>
								</div>
								<div className="w-full flex items-center justify-center gap-4">
									<span className="w-full">
										<label htmlFor="description">Beschreibung</label>
										<div className="flex flex-col items-start justify-start gap-2">
											<Textarea
												placeholder="Beschreiben Sie ihr Produkt..."
												id="description"
												value={description}
												onChange={(e) => setDescription(e.target.value)}
											/>
										</div>
									</span>
								</div>
							</Card>

							{/* Bilder hochladen */}
							<Card className="w-full h-full gap-4 py-8 pl-16 pr-16 items-start justify-start">
								<div className="grid grid-cols-3 gap-2 mt-4">
									{imageFiles && imageFiles.length === 0 ? (
										<ImageIcon className="w-96 h-96 text-sidebarGray-500" />
									) : (
										imageFiles.map((image: CustomImageFile) => (
											<div
												key={image.id}
												className="relative border-sidebarGray-500 border-[1px]"
											>
												<img
													src={`${image.imageUrl}`}
													alt={searchTerm}
													className="w-full h-full max-h-[250px] object-contain"
												/>
												<Button
													onClick={() => deleteImage(image.id, articleId)}
													className="absolute top-2 right-2 px-2 py-0 m-0"
													variant="ghost"
												>
													<TrashIcon className="w-4 h-4" />
												</Button>
											</div>
										))
									)}
								</div>
								<h3 className="mb-2 text-lg font-medium">Bilder hochladen</h3>
								<div
									className={`border-2 border-dashed border-gray-400 p-4 rounded-lg w-full flex justify-center items-center ${
										isDragging ? "bg-sidebarGray-500" : "bg-transparent"
									}`}
									onDrop={handleDrop}
									onDragOver={handleDragOver}
									onDragEnter={handleDragEnter}
									onDragLeave={handleDragLeave}
								>
									{isDragging ? (
										<p className="text-sidebarGrayLight-800 flex items-center justify-center gap-4">
											<DownloadIcon className="w-16 h-16" />
											Lassen Sie die Dateien los, um sie hochzuladen
										</p>
									) : (
										<label className="cursor-pointer text-gray-500">
											<span className="flex items-center justify-center gap-4">
												<ImageIcon className="w-16 h-16" />
												Ziehen Sie Ihre Bilder hierher oder klicken Sie
											</span>
											<input
												type="file"
												accept="image/*"
												multiple
												onChange={handleImageUpload}
												className="hidden"
											/>
										</label>
									)}
								</div>
								{selectedImages.length > 0 && (
									<div className="mt-4 grid grid-cols-2 gap-4">
										{selectedImages.map((image, index) => {
											const imageUrl = URL.createObjectURL(image);
											return (
												<div key={index} className="relative">
													<img
														src={imageUrl}
														alt={`preview-${index}`}
														className="w-full h-auto object-cover rounded-md"
													/>
													<TrashIcon
														className="p-2 w-8 h-8 text-sidebarGrayLight-400 rounded-md bg-sidebarGray-700 absolute right-1 top-1 hover:pointer"
														onClick={() => removeImage(index)}
													/>
												</div>
											);
										})}
									</div>
								)}
							</Card>
						</div>

						{/* Rechte Spalte */}
						<div className="grid grid-cols-1 w-2/5 gap-4">
							{/* Kategorie */}
							<Card className="flex h-full flex-grow flex-col w-full gap-4 py-8 pl-16 pr-16 items-start justify-start">
								<h3 className="mb-2 text-lg font-medium">Kategorie</h3>
								<label htmlFor="select">Produktkategorie</label>
								<Select value={productGroup} onValueChange={(value) => setProductGroup(value)}>
									<SelectTrigger className="w-full" id="select">
										<SelectValue placeholder="Wählen Sie eine Kategorie aus" />
									</SelectTrigger>
									<SelectContent>
										<SelectGroup className="p-0 m-0 flex flex-col items-start justify-start">
											<SelectLabel className="p-0 m-0">Produktkategorie</SelectLabel>
											{Array.isArray(productGroups) && productGroups.length > 0 ? (
												productGroups.map((group) =>
													group ? (
														<SelectItem className="p-0 m-0" value={group} key={group}>
															{group}
														</SelectItem>
													) : null
												)
											) : (
												<></>
											)}
										</SelectGroup>
									</SelectContent>
								</Select>
								<Label htmlFor="productgroup"></Label>
								<Input
									id="productgroup"
									type="text"
									value={productGroup}
									onChange={(e) => setProductGroup(e.target.value)}
								></Input>
							</Card>

							{/* Preisinformationen */}
							<Card className="flex flex-col w-full gap-4 py-8 pl-16 pr-16 items-start justify-start">
								<h3 className="mb-2 text-lg font-medium">Preisinformationen</h3>
								<div className="w-full flex items-center justify-center gap-4">
									<span className="w-full">
										<label htmlFor="basePrice">Bruttopreis</label>
										<div className="relative w-full">
											<div className="border bg-sidebarGray-500 border-sidebarGray-500 rounded-sm absolute top-1 left-1 px-2 py-[1px]">
												EUR
											</div>
											<Input
												id="basePrice"
												type="text"
												value={price === 0 ? "" : price.toString()}
												onChange={(e) => {
													const decimalValue = e.target.value.replace(/[^0-9.]/g, "");
													setPrice(Number(decimalValue));
												}}
												className="pl-16"
											></Input>
										</div>
									</span>
								</div>
							</Card>

							{/* Produktstatus */}
							<Card className="flex flex-col w-full gap-4 py-8 pl-16 pr-16 items-start justify-evenly">
								<h3 className="mb-2 text-lg font-medium">Produktstatus</h3>
								<div className="flex flex-col items-start justify-start">
									<Label htmlFor="active">Aktiv</Label>
									<input
										checked={active}
										type="checkbox"
										id="active"
										onChange={(e) => setActive(e.target.checked)}
									></input>
								</div>
								<div className="flex flex-col items-start justify-start">
									<Label htmlFor="showPrice">Preis anzeigen</Label>
									<input
										checked={showPrice}
										type="checkbox"
										id="showPrice"
										onChange={(e) => setShowPrice(e.target.checked)}
									></input>
								</div>
							</Card>
							<Card className="w-full gap-4 grid grid-cols-1 py-8 pl-16 pr-16 items-start justify-start border-red-400 border-[1px]">
								<span className="flex items-center gap-4  justify-start text-base text-gray-500 pt-1">
									<ExclamationTriangleIcon className="w-6 h-6 text-red-400" />
									Produkt endgültig löschen
								</span>
								<small>
									Bitte beachten Sie, dass dieser Vorgang nicht rückgängig gemacht werden kann. Auch
									nicht von uns wenn es sich um ein Versehen handelt.
								</small>
								<div>
									<Button
										variant={"destructive"}
										className="text-red-400 border-[1px] border-red-400"
										onClick={() => deleteProduct(articleId)}
									>
										Produkt jetzt löschen
									</Button>
								</div>
							</Card>
						</div>
					</section>
					<Button variant={"default"} className="w-full mt-8" onClick={handleUpdateProduct}>
						{isLoading ? "Speichert..." : "Änderungen speichern"}
					</Button>
				</div>
			</div>
		</>
	);
};

export default UpdateCustomProductSheet;
