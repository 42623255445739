import { effect, signal } from "@preact/signals-react";
import { NavLink, useNavigate } from "react-router-dom";
import { useOrdersShop } from "../../helper/OrderWebshopContext";
import OrderCardWebshop from "../../components/cards/OrderCardWebshop";
import useAuthRequest from "../../../utils/useAuthRequest";
import { getUserRoleFromToken, isTokenExpired } from "../../../utils/auth";
import { useState } from "react";

export default function OrderTablePacked() {
	const [isLoadingPickUp, setIsLoadingPickUp] = useState(false);
	const sendRequest = useAuthRequest();
	const navigate = useNavigate();
	const token = localStorage.getItem("authToken");
	const userRole = getUserRoleFromToken();
	const { ordersPickUpShop, fetchOrdersShop } = useOrdersShop();

	effect(() => {
		if (!token || !userRole || isTokenExpired(token) || userRole !== "bucher") {
			navigate("/login");
		}
		fetchOrdersShop("packed");
		const intervalId = setInterval(() => {
			fetchOrdersShop("packed");
		}, 1000);

		// Cleanup-Funktion
		return () => clearInterval(intervalId);
	});

	const sendOrderConfirmation = async (orderId: string) => {
		try {
			const response = await sendRequest(`/api/mail/send-order-confirmation`, "post", { orderId });
			if (response.status !== 200) {
				throw new Error("Fehler beim Senden der Bestellbestätigung");
			}
		} catch (error) {
			console.error("Fehler beim Senden der Bestellbestätigung:", error);
		}
	};

	const handleShipOrderAndSendConfirmation = async (orderId: string) => {
		setIsLoadingPickUp(true);
		try {
			const response = await sendRequest<{ newStatus: string }>(`/api/webshop/order/${orderId}/status`, "patch", {
				newStatus: "sent",
			});

			if (response.status === 200) {
				await sendOrderConfirmation(orderId);
				fetchOrdersShop("pending");
				fetchOrdersShop("picked");
				fetchOrdersShop("packed");
				fetchOrdersShop("sent");
			} else {
				throw new Error("Fehler beim Aktualisieren des Bestellstatus");
			}
		} catch (error) {
			console.error("Fehler beim Verarbeiten der Bestellung:", error);
		} finally {
			setIsLoadingPickUp(false);
		}
	};

	return (
		<>
			<div className="text-gray-900 dark:text-gray-50 flex flex-col gap-4 p-6">
				{ordersPickUpShop.length > 0 ? (
					ordersPickUpShop.map((order) => (
						<OrderCardWebshop
							key={order.orderId}
							order={order}
							onCompleteOrder={handleShipOrderAndSendConfirmation}
							isLoadingPickUp={isLoadingPickUp}
						/>
					))
				) : (
					<div className="flex-col items-start justify-start bg-gray-300 dark:bg-sidebarGray-700 rounded-lg p-6">
						<h1 className="font-bold text-3xl">Es wurde keine bereits verpackten Bestellungen gefunden.</h1>
					</div>
				)}
			</div>
		</>
	);
}
