import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getUserRoleFromToken, isTokenExpired } from "../../../../utils/auth";
import OrderCard from "../../../components/cards/OrderCard";
import { useOrders } from "../../../helper/OrderContext";
import "../styles/OttoStyles.css";

type Dimension = {
	displayName: string;
	type: string;
	value: string;
};

type Product = {
	title: string;
	salesPrice: string;
	articleNumber: string;
	ean: string;
	fulfillmentStatus: string;
	mainImageBase64?: string;
};

type Order = {
	id: string;
	orderNumber: string;
	orderDate: string;
	products: Product[];
	fulfillmentStatus: string;
};

export default function OrderHistoryPage() {
	const navigate = useNavigate();
	const token = localStorage.getItem("authToken");
	const userRole = getUserRoleFromToken();
	const [searchParams] = useSearchParams();
	const searchTerm = searchParams.get("search") || "";
	const { ordersHistory, fetchOrdersHistory } = useOrders();
	const [page, setPage] = useState(1);

	useEffect(() => {
		if (
			!token ||
			!userRole ||
			isTokenExpired(token) ||
			userRole !== "bucher"
		) {
			navigate("/login");
		} else {
			fetchOrdersHistory(1);
		}
	}, [token, userRole, navigate]);

	useEffect(() => {
		if (page > 1) {
			fetchOrdersHistory(page);
		}
	}, [page]);

	function handleListExpansion() {
		setPage((prevPage) => prevPage + 1);
	}

	return (
		<>
			<div className="text-gray-900 dark:text-gray-50 flex flex-col gap-4 p-6">
				{ordersHistory.length > 0 ? (
					ordersHistory.map((order) => (
						<OrderCard key={order.id} order={order} />
					))
				) : (
					<div className="flex flex-col items-start justify-start bg-gray-300 dark:bg-sidebarGray-500 rounded-lg p-6">
						<h1 className="font-bold text-3xl">
							Es wurden keine historischen Bestellungen gefunden
						</h1>
					</div>
				)}
			</div>
			{ordersHistory.length > 0 ? (
				<button
					className="z-50 py-8 mx-auto flex items-center justify-center text-sidebarGray-400 hover:text-white"
					onClick={handleListExpansion}
				>
					Mehr Anzeigen
				</button>
			) : (
				<></>
			)}
		</>
	);
}
