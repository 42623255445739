import { ImageIcon, ValueNoneIcon } from "@radix-ui/react-icons";
import { Package2, PieChart, RocketIcon, TrendingDownIcon, TrendingUp, TrendingUpIcon } from "lucide-react";
import { useContext, useEffect, useState } from "react";
import {
	Bar,
	BarChart,
	CartesianGrid,
	Cell,
	Label,
	Pie,
	PolarGrid,
	PolarRadiusAxis,
	RadialBar,
	RadialBarChart,
	XAxis,
} from "recharts";
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "src/components/ui/card";
import {
	ChartConfig,
	ChartContainer,
	ChartLegend,
	ChartLegendContent,
	ChartTooltip,
	ChartTooltipContent,
} from "src/components/ui/chart";
import { AnalyticsContext } from "../../helper/AnalyticsContext";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "src/components/ui/select";
import { AnalyticsWebshopContext } from "../../helper/AnalyticsWebshopContext";

function formatDate(date: Date): string {
	const day = String(date.getDate()).padStart(2, "0");
	const month = String(date.getMonth() + 1).padStart(2, "0");
	const year = date.getFullYear();
	return `${day}.${month}.${year}`;
}

function formatMonthYear(date: Date): string {
	const month = String(date.getMonth() + 1).padStart(2, "0");
	const year = date.getFullYear();
	return `${month}.${year}`;
}

function formatYear(date: Date): string {
	const year = date.getFullYear();
	return `${year}`;
}

export default function WebshopDashboard() {
	const {
		fetchMonthlyWebshopSales,
		fetchTopWebshopProducts,
		fetchYearlyWebshopSales,
		monthlySalesWebshop,
		topProductsWebshop,
		yearlySalesWebshop,
	} = useContext(AnalyticsWebshopContext);
	type ConfigKey = keyof typeof chartConfigs;
	const currentYear = new Date().getFullYear();
	const years = Array.from({ length: 10 }, (_, i) => currentYear - i);
	const months = [
		{ label: "Ganzes Jahr", startDate: new Date(currentYear, 0), endDate: new Date(currentYear, 11) },
		{ label: "Januar", startDate: new Date(currentYear, 0), endDate: new Date(currentYear, 0) },
		{ label: "Februar", startDate: new Date(currentYear, 1), endDate: new Date(currentYear, 1) },
		{ label: "März", startDate: new Date(currentYear, 2), endDate: new Date(currentYear, 2) },
		{ label: "April", startDate: new Date(currentYear, 3), endDate: new Date(currentYear, 3) },
		{ label: "Mai", startDate: new Date(currentYear, 4), endDate: new Date(currentYear, 4) },
		{ label: "Juni", startDate: new Date(currentYear, 5), endDate: new Date(currentYear, 5) },
		{ label: "Juli", startDate: new Date(currentYear, 6), endDate: new Date(currentYear, 6) },
		{ label: "August", startDate: new Date(currentYear, 7), endDate: new Date(currentYear, 7) },
		{ label: "September", startDate: new Date(currentYear, 8), endDate: new Date(currentYear, 8) },
		{ label: "Oktober", startDate: new Date(currentYear, 9), endDate: new Date(currentYear, 9) },
		{ label: "November", startDate: new Date(currentYear, 10), endDate: new Date(currentYear, 10) },
		{ label: "Dezember", startDate: new Date(currentYear, 11), endDate: new Date(currentYear, 11) },
	];

	const [selectedYear, setSelectedYear] = useState(currentYear);
	const [selectedMonthIndex, setSelectedMonthIndex] = useState(0); // Standard: Ganzes Jahr
	const [selectedConfig, setSelectedConfig] = useState<ConfigKey>("ordersReturns");

	function formatPrice(value: number): string {
		return (
			value
				.toFixed(2)
				.replace(".", ",")
				.replace(/\B(?=(\d{3})+(?!\d))/g, ".") + " €"
		);
	}

	useEffect(() => {
		const formattedYear = formatYear(new Date(selectedYear, 0));
		fetchTopWebshopProducts();
		if (selectedMonthIndex === 0) {
			fetchYearlyWebshopSales(formattedYear, formattedYear);
			fetchMonthlyWebshopSales(`01.${selectedYear}`, `12.${selectedYear}`);
		} else {
			const selectedMonth = months[selectedMonthIndex];
			const formattedMonthlyStartDate = formatMonthYear(selectedMonth.startDate);
			const formattedMonthlyEndDate = formatMonthYear(selectedMonth.endDate);
			fetchMonthlyWebshopSales(formattedMonthlyStartDate, formattedMonthlyEndDate);
		}
	}, [selectedYear, selectedMonthIndex]);

	const handleMonthChange = (value: string) => {
		setSelectedMonthIndex(parseInt(value));
	};

	const handleYearChange = (value: string) => {
		setSelectedYear(parseInt(value));
	};

	const chartConfig = {
		orderCount: {
			label: "Bestellungen",
			color: "#2563eb",
		},
		averageOrderValue: {
			label: "Bestellwert",
			color: "#60a5fa",
		},
	} satisfies ChartConfig;

	const chartConfigOrderReturn = {
		orderCount: {
			label: "Bestellungen",
			color: "#2563eb",
		},
		returnCount: {
			label: "Retouren",
			color: "#60a5fa",
		},
	} satisfies ChartConfig;

	const chartConfigSalesWinnings = {
		totalSales: {
			label: "potenzielle Verkäufe",
			color: "#2563eb",
		},
		actualSales: {
			label: "tatsächliche  Verkäufe",
			color: "#60a5fa",
		},
	} satisfies ChartConfig;

	const chartConfigs = {
		ordersReturns: chartConfigOrderReturn,
		salesWinnings: chartConfigSalesWinnings,
	};

	const handleSelectChange = (value: ConfigKey) => {
		setSelectedConfig(value);
	};

	return (
		<>
			<div className="w-full flex items-center justify-start border-b-[1px] border-border py-2">
				<label htmlFor="monthSelect" className="mr-2">
					Monat auswählen:
				</label>
				<Select onValueChange={handleMonthChange} defaultValue="0">
					<SelectTrigger className="w-[180px]">
						<SelectValue placeholder="Monat auswählen" />
					</SelectTrigger>
					<SelectContent>
						{months.map((month, index) => (
							<SelectItem key={index} value={index.toString()}>
								{month.label}
							</SelectItem>
						))}
					</SelectContent>
				</Select>

				<label htmlFor="yearSelect" className="ml-4 mr-2">
					Jahr auswählen:
				</label>
				<Select onValueChange={handleYearChange} defaultValue={currentYear.toString()}>
					<SelectTrigger className="w-[180px]">
						<SelectValue placeholder="Jahr auswählen" />
					</SelectTrigger>
					<SelectContent>
						{years.map((year) => (
							<SelectItem key={year} value={year.toString()}>
								{year}
							</SelectItem>
						))}
					</SelectContent>
				</Select>
			</div>

			<h1 className="mt-6 text-4xl">Webshop Analytics</h1>

			{selectedMonthIndex === 0
				? yearlySalesWebshop &&
				  yearlySalesWebshop.map((yearly) => (
						<div>
							<section className="mt-6 flex md:grid lg:grid-cols-2 xl:flex flex-col xl:flex-row xl:items-center xl:justify-between w-full mb-6 gap-6">
								<Card className="w-full">
									<CardHeader>
										<CardTitle className="flex items-center justify-between">
											<span className="text-base text-gray-500">Gesamte Verkaufserlöse</span>
											<RocketIcon className="w-6 h-6 text-gray-500" />
										</CardTitle>
									</CardHeader>
									<CardContent>
										<p className="text-4xl font-bold">{formatPrice(yearly.totalSales)}</p>
									</CardContent>
									<CardFooter className="flex justify-start gap-2">
										{yearly.totalSalesChangePercent === 0 ? (
											<>
												<p className="text-gray-400">Keine Änderung zum Vorjahr</p>
											</>
										) : yearly.totalSalesChangePercent && yearly.totalSalesChangePercent < 0 ? (
											<>
												<span className="text-red-400">{yearly.totalSalesChangePercent}%</span>
												<p className="text-red-400">zum Vorjahr</p>
												<TrendingDownIcon className="text-red-400 w-4 h-4" />
											</>
										) : (
											<>
												<span className="text-green-400">
													+{yearly.totalSalesChangePercent}%
												</span>
												<p className="text-green-400">zum Vorjahr</p>
												<TrendingUpIcon className="text-green-400 w-4 h-4" />
											</>
										)}
									</CardFooter>
								</Card>
								<Card className="w-full">
									<CardHeader>
										<CardTitle className="flex items-center justify-between">
											<span className="text-base text-gray-500">Gesamte Bestellungen</span>
											<Package2 className="w-6 h-6 text-gray-500" />
										</CardTitle>
									</CardHeader>
									<CardContent>
										<p className="text-4xl font-bold">{yearly.orderCount}</p>
									</CardContent>
									<CardFooter className="flex justify-start gap-2">
										{yearly.orderCountChangePercent === 0 ? (
											<>
												<p className="text-gray-400">Keine Änderung zum Vorjahr</p>
											</>
										) : yearly.orderCountChangePercent && yearly.orderCountChangePercent < 0 ? (
											<>
												<span className="text-red-400">{yearly.orderCountChangePercent}%</span>
												<p className="text-red-400">zum Vorjahr</p>
												<TrendingDownIcon className="text-red-400 w-4 h-4" />
											</>
										) : (
											<>
												<span className="text-green-400">
													+{yearly.orderCountChangePercent}%
												</span>
												<p className="text-green-400">zum Vorjahr</p>
												<TrendingUpIcon className="text-green-400 w-4 h-4" />
											</>
										)}
									</CardFooter>
								</Card>
								{/*
								<Card className="w-full">
									<CardHeader>
										<CardTitle className="flex items-center justify-between">
											<span className="text-base text-gray-500">
												Durchschnittliche Retourenquote
											</span>
											<ValueNoneIcon className="w-6 h-6 text-gray-500" />
										</CardTitle>
									</CardHeader>
									<CardContent>
										<p className="text-4xl font-bold">{yearly.averageOrderValue}%</p>
									</CardContent>
									<CardFooter className="flex justify-start gap-2">
										<p className="text-gray-500">-</p>
									</CardFooter>
								</Card>
								*/}
								<Card className="w-full">
									<CardHeader>
										<CardTitle className="flex items-center justify-between">
											<span className="text-base text-gray-500">
												Durchschnittlicher Bestellwert
											</span>
											<ValueNoneIcon className="w-6 h-6 text-gray-500" />
										</CardTitle>
									</CardHeader>
									<CardContent>
										<p className="text-4xl font-bold">
											{monthlySalesWebshop && formatPrice(yearly.averageOrderValue)}
										</p>
									</CardContent>
									<CardFooter className="flex  justify-start gap-2">
										<p className="text-gray-500">-</p>
									</CardFooter>
								</Card>
							</section>
							<h1 className="mt-12 text-4xl">Verkaufsstatistiken</h1>
							<div className="grid grid-cols-2 gap-4 w-full mt-6">
								<div className="flex flex-col items-start justify-start">
									<Select value={selectedConfig} onValueChange={handleSelectChange}>
										<SelectTrigger className="border rounded p-2 mb-4 w-1/3">
											<SelectValue placeholder="Wähle eine Option" />
										</SelectTrigger>
										<SelectContent>
											<SelectItem value="ordersReturns">Bestellungen | Retouren</SelectItem>
											<SelectItem value="salesWinnings">
												potenzielle Verkäufe | Tatsächliche Verkäufe
											</SelectItem>
										</SelectContent>
									</Select>
									<div className="flex flex-col items-start justify-start border-[1px] border-gray-600 rounded-lg w-full h-full">
										<span className="text-gray-500 text-xl w-full rounded-t-lg p-3 border-b-gray-500 border-[1px]">
											Bestellungen | Retouren pro Monat
										</span>
										<ChartContainer config={chartConfigs[selectedConfig]} className="h-full w-full">
											<BarChart accessibilityLayer data={yearlySalesWebshop}>
												<CartesianGrid vertical={false} />
												<XAxis
													dataKey="month"
													tickLine={false}
													tickMargin={10}
													axisLine={false}
													tickFormatter={(value) => value.slice(0, 3)}
												/>
												<ChartTooltip content={<ChartTooltipContent />} />
												<ChartLegend content={<ChartLegendContent />} />

												{selectedConfig === "ordersReturns" ? (
													<>
														<Bar
															dataKey="orderCount"
															fill={chartConfigs.ordersReturns.orderCount.color}
															radius={4}
														/>
														<Bar
															dataKey="returnCount"
															fill={chartConfigs.ordersReturns.returnCount.color}
															radius={4}
														/>
													</>
												) : (
													<>
														<Bar
															dataKey="totalSales"
															fill={chartConfigs.salesWinnings.totalSales.color}
															radius={4}
														/>
														<Bar
															dataKey="actualSales"
															fill={chartConfigs.salesWinnings.actualSales.color}
															radius={4}
														/>
													</>
												)}
											</BarChart>
										</ChartContainer>
									</div>
								</div>
							</div>
							<h1 className="mt-12 text-4xl">Topseller</h1>
							<div className="flex flex-col items-start justify-start w-full h-full mt-6">
								<Card className="w-full h-full">
									<CardHeader>Verkaufsschlager</CardHeader>
									<CardDescription className="px-6">
										Hier sehen die top 10 Bestellungen
									</CardDescription>
									<CardContent className="grid grid-cols-2">
										{topProductsWebshop &&
											topProductsWebshop.map((top) => (
												<div className="flex items-start justify-center w-full mt-6 gap-4">
													<img
														src={`data:image/jpeg;base64,${top.mainImage}`}
														width={75}
														height={75}
														alt=""
													/>
													<div className="w-full flex-col items-start justify-center  text-left flex gap-4 font-medium leading-none">
														<p className="text-xs text-sidebarGray-500">{top.articleNr}</p>
														<p>{top.productName}</p>
														<p className="text-xs text-sidebarGray-500">
															Verkaufte Einheiten: {top.unitsSold}
														</p>
													</div>
												</div>
											))}
									</CardContent>
								</Card>
							</div>
						</div>
				  ))
				: monthlySalesWebshop &&
				  monthlySalesWebshop.map((monthly) => (
						<>
							<section className="mt-6 flex md:grid lg:grid-cols-2 xl:flex flex-col xl:flex-row xl:items-center xl:justify-between w-full mb-6 gap-6">
								<Card className="w-full">
									<CardHeader>
										<CardTitle className="flex items-center justify-between">
											<span className="text-base text-gray-500">Gesamte Verkaufserlöse</span>
											<RocketIcon className="w-6 h-6 text-gray-500" />
										</CardTitle>
									</CardHeader>
									<CardContent>
										<p className="text-4xl font-bold">
											{monthlySalesWebshop && formatPrice(monthly.totalSales)}
										</p>
									</CardContent>
									<CardFooter className="flex  justify-start gap-2">
										{monthly.totalSalesChangePercent === 0 ? (
											<>
												<p className="text-gray-400">Keine Änderung zum Vormonat</p>
											</>
										) : monthly.totalSalesChangePercent && monthly.totalSalesChangePercent < 0 ? (
											<>
												<span className="text-red-400">{monthly.totalSalesChangePercent}%</span>
												<p className="text-red-400">zum Vormonat</p>
												<TrendingDownIcon className="text-red-400 w-4 h-4" />
											</>
										) : (
											<>
												<span className="text-green-400">
													+{monthly.totalSalesChangePercent}%
												</span>
												<p className="text-green-400">zum Vormonat</p>
												<TrendingUpIcon className="text-green-400 w-4 h-4" />
											</>
										)}
									</CardFooter>
								</Card>
								<Card className="w-full">
									<CardHeader>
										<CardTitle className="flex items-center justify-between">
											<span className="text-base text-gray-500">Gesamte Bestellungen</span>
											<Package2 className="w-6 h-6 text-gray-500" />
										</CardTitle>
									</CardHeader>
									<CardContent>
										<p className="text-4xl font-bold">{monthly.orderCount}</p>
									</CardContent>
									<CardFooter className="flex justify-start gap-2">
										{monthly.orderCountChangePercent === 0 ? (
											<>
												<p className="text-gray-400">Keine Änderung zum Vormonat</p>
											</>
										) : monthly.orderCountChangePercent && monthly.orderCountChangePercent < 0 ? (
											<>
												<span className="text-red-400">{monthly.orderCountChangePercent}%</span>
												<p className="text-red-400">zum Vormonat</p>
												<TrendingDownIcon className="text-red-400 w-4 h-4" />
											</>
										) : (
											<>
												<span className="text-green-400">
													+{monthly.orderCountChangePercent}%
												</span>
												<p className="text-green-400">zum Vormonat</p>
												<TrendingUpIcon className="text-green-400 w-4 h-4" />
											</>
										)}
									</CardFooter>
								</Card>
								<Card className="w-full">
									<CardHeader>
										<CardTitle className="flex items-center justify-between">
											<span className="text-base text-gray-500">
												Durchschnittliche Retourenquote
											</span>
											<ValueNoneIcon className="w-6 h-6 text-gray-500" />
										</CardTitle>
									</CardHeader>
									<CardContent>
										<p className="text-4xl font-bold">{}%</p>
									</CardContent>
									<CardFooter className="flex justify-between">
										<p className="text-gray-500">-</p>
									</CardFooter>
								</Card>
								<Card className="w-full">
									<CardHeader>
										<CardTitle className="flex items-center justify-between">
											<span className="text-base text-gray-500">
												Durchschnittlicher Bestellwert
											</span>
											<ValueNoneIcon className="w-6 h-6 text-gray-500" />
										</CardTitle>
									</CardHeader>
									<CardContent>
										<p className="text-4xl font-bold">
											{monthlySalesWebshop && formatPrice(monthly.averageOrderValue)}
										</p>
									</CardContent>
									<CardFooter className="flex justify-between">
										<p className="text-gray-500">-</p>
									</CardFooter>
								</Card>
							</section>
							<h1 className="mt-12 text-4xl">Verkaufsstatistiken</h1>
							<div className="grid grid-cols-2 gap-4 w-full mt-6">
								<div className="flex flex-col items-start justify-start w-full">
									<Select value={selectedConfig} onValueChange={handleSelectChange}>
										<SelectTrigger className="border rounded p-2 mb-4 w-1/3">
											<SelectValue placeholder="Wähle eine Option" />
										</SelectTrigger>
										<SelectContent>
											<SelectItem value="ordersReturns">Bestellungen | Retouren</SelectItem>
											<SelectItem value="salesWinnings">
												potenzielle Verkäufe | Tatsächliche Verkäufe
											</SelectItem>
										</SelectContent>
									</Select>
									<div className="flex flex-col items-start justify-start border-[1px] border-gray-600 rounded-lg w-full h-full">
										<span className="text-gray-500 text-xl w-full rounded-t-lg p-3 border-b-gray-500 border-[1px]">
											Bestellungen | Retouren pro Monat
										</span>
										<ChartContainer config={chartConfigs[selectedConfig]} className="h-full w-full">
											<BarChart accessibilityLayer data={monthlySalesWebshop}>
												<CartesianGrid vertical={false} />
												<XAxis
													dataKey="month"
													tickLine={false}
													tickMargin={10}
													axisLine={false}
													tickFormatter={(value) => value.slice(0, 3)}
												/>
												<ChartTooltip content={<ChartTooltipContent />} />
												<ChartLegend content={<ChartLegendContent />} />

												{selectedConfig === "ordersReturns" ? (
													<>
														<Bar
															dataKey="orderCount"
															fill={chartConfigs.ordersReturns.orderCount.color}
															radius={4}
														/>
														<Bar
															dataKey="returnCount"
															fill={chartConfigs.ordersReturns.returnCount.color}
															radius={4}
														/>
													</>
												) : (
													<>
														<Bar
															dataKey="totalSales"
															fill={chartConfigs.salesWinnings.totalSales.color}
															radius={4}
														/>
														<Bar
															dataKey="actualSales"
															fill={chartConfigs.salesWinnings.actualSales.color}
															radius={4}
														/>
													</>
												)}
											</BarChart>
										</ChartContainer>
									</div>
								</div>
							</div>
							<h1 className="mt-12 text-4xl">Topseller</h1>
							<div className="flex flex-col items-start justify-start w-full h-full mt-6">
								<Card className="w-full h-full">
									<CardHeader>Verkaufsschlager</CardHeader>
									<CardDescription className="px-6">
										Hier sehen die top 10 Bestellungen
									</CardDescription>
									<CardContent className="grid grid-cols-2">
										{topProductsWebshop &&
											topProductsWebshop.map((top) => (
												<div className="flex items-start justify-center w-full mt-6 gap-4">
													<img
														src={`data:image/jpeg;base64,${top.mainImage}`}
														width={75}
														height={75}
														alt=""
													/>
													<div className="w-full flex-col items-start justify-center  text-left flex gap-4 font-medium leading-none">
														<p className="text-xs text-sidebarGray-500">{top.articleNr}</p>
														<p>{top.productName}</p>
														<p className="text-xs text-sidebarGray-500">
															Verkaufte Einheiten: {top.unitsSold}
														</p>
													</div>
												</div>
											))}
									</CardContent>
								</Card>
							</div>
						</>
				  ))}
		</>
	);
}
