import { effect, signal } from "@preact/signals-react";
import axios from "axios";
import useAuthRequest from "../../../../utils/useAuthRequest";
import "../styles/OttoStyles.css";
import { useNavigate } from "react-router-dom";
import { getUserRoleFromToken, isTokenExpired } from "../../../../utils/auth";
import OrderCard from "../../../components/cards/OrderCard";
import { useOrders } from "../../../helper/OrderContext";
import { parseISO, subMonths } from "date-fns";

type Dimension = {
	displayName: string;
	type: string;
	value: string;
};

type Product = {
	title: string;
	salesPrice: string;
	articleNumber: string;
	ean: string;
	fulfillmentStatus: string;
	mainImageBase64?: string;
	count?: number;
};

type Order = {
	id: string;
	orderNumber: string;
	orderDate: string;
	products: Product[];
	fulfillmentStatus: string;
	handeledReturn: boolean;
};

function RetourenPage() {
	const sendRequest = useAuthRequest();
	const navigate = useNavigate();
	const token = localStorage.getItem("authToken");
	const userRole = getUserRoleFromToken();
	const { ordersReturn, fetchOrders } = useOrders();

	effect(() => {
		if (
			!token ||
			!userRole ||
			isTokenExpired(token) ||
			userRole !== "bucher"
		) {
			navigate("/login");
		}
		fetchOrders("returned");
		const intervalId = setInterval(() => {
			fetchOrders("returned");
		}, 1000);

		// Cleanup-Funktion
		return () => clearInterval(intervalId);
	});

	const filterRecentOrders = (orders: Order[]) => {
		const oneMonthsAgo = subMonths(new Date(), 1);
		return orders.filter((order) => {
			const orderDate = parseISO(order.orderDate);
			return (
				orderDate > oneMonthsAgo &&
				order.fulfillmentStatus === "RETURNED" &&
				(order.handeledReturn === undefined || !order.handeledReturn)
			);
		});
	};

	const handleReturnOrder = async (orderId: string) => {
		try {
			const response = await sendRequest(
				`/api/otto/acceptance/${orderId}`,
				"post"
			);
			if (response.status === 200) {
				console.log(response.data.message);
			} else {
				throw new Error("Fehler bei der Annahme der Retoure");
			}
		} catch (error) {
			console.error("Fehler bei der Annahme der Retoure:", error);
		}
	};

	const handleDenyReturn = async (
		orderId: string,
		reason: string,
		condition: string
	) => {
		try {
			const response = await sendRequest(
				`/api/otto/rejection/${orderId}`,
				"post",
				{ reason, condition }
			);
			if (response.status === 200) {
				console.log(response.data.message);
			} else {
				throw new Error("Fehler bei der Ablehnung der Retoure");
			}
		} catch (error) {
			console.error("Fehler bei der Ablehnung der Retoure:", error);
		}
	};

	return (
		<>
			<div className="text-gray-900 dark:text-gray-50 flex flex-col gap-4 p-6">
				{ordersReturn.length > 0 ? (
					ordersReturn.map((order) => (
						<OrderCard
							key={order.id}
							order={order}
							onReturnOrder={handleReturnOrder}
							onDenyReturn={handleDenyReturn}
						/>
					))
				) : (
					<div className="flex flex-col items-start justify-start bg-gray-300 dark:bg-sidebarGray-700 rounded-lg p-6">
						<h1 className="font-bold text-3xl">
							Es wurden keine aktuellen Retouren gefunden.
						</h1>
					</div>
				)}
			</div>
		</>
	);
}

export default RetourenPage;
