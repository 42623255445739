import React from "react";
import { ReactComponent as ArcView } from "../../assets/icons/arcViewLogo.svg";
import { ReactComponent as ArcViewAlt } from "../../assets/icons/LogoLight.svg";
import { ReactComponent as ArcViewAltDark } from "../../assets/icons/LogoDark.svg";
import { ReactComponent as Otto } from "../../assets/icons/Otto.svg";
import { ReactComponent as Product } from "../../assets/icons/Produktuebersicht.svg";
import { ReactComponent as Shop } from "../../assets/icons/Webshop.svg";
import { ReactComponent as Settings } from "../../assets/icons/settings.svg";
import { ReactComponent as Search } from "../../assets/icons/search.svg";
import { ReactComponent as Return } from "../../assets/icons/search.svg";
import { ReactComponent as Icon } from "../../assets/icons/dashboard.svg";

const OttoIcon: React.FC = () => {
	return <Otto className="w-5 h-5" />;
};

const ShopIcon: React.FC = () => {
	return <Shop className="w-5 h-5" />;
};

const ProductIcon: React.FC = () => {
	return <Product className="w-5 h-5" />;
};

const SettingsIcon: React.FC = () => {
	return <Settings className="w-5 h-5 stroke-current text-white" />;
};

const ArcViewIcon: React.FC = () => {
	return <ArcView className="w-8 h-8" />;
};
const ArcViewIconAlt: React.FC = () => {
	return <ArcViewAlt className="w-44 h-10" />;
};
const ArcViewIconAltDark: React.FC = () => {
	return <ArcViewAltDark className="w-44 h-10" />;
};
const SearchIcon: React.FC = () => {
	return <Search className="w-5 h-5 stroke-current text-sidebarGray-500" />;
};
const ReturnIcon: React.FC = () => {
	return <Return className="w-5 h-5 stroke-current text-sidebarGray-500" />;
};
const ProfileCollapseIcon: React.FC = () => {
	return <Search className="w-5 h-5 stroke-current text-sidebarGray-500" />;
};
const ArcViewIconSVG: React.FC = () => {
	return <Icon className="w-5 h-5 stroke-current text-sidebarGray-500" />;
};

const Icons = {
	OttoIcon: OttoIcon,
	ShopIcon: ShopIcon,
	SettingsIcon: SettingsIcon,
	ArcViewIcon: ArcViewIcon,
	SearchIcon: SearchIcon,
	ArcViewAlt: ArcViewIconAlt,
	ArcViewAltDark: ArcViewIconAltDark,
	ReturnIcon: ReturnIcon,
	ProductIcon: ProductIcon,
};

export default Icons;
