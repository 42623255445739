import React, { ReactNode, createContext, useContext, useState } from "react";
import axios, { AxiosResponse } from "axios";
import useAuthRequest from "../../utils/useAuthRequest";
import { parseISO, subMonths } from "date-fns";
import { useNavigate } from "react-router-dom";

type WebshopProduct = {
  id: string;
  articleNr: number;
  ean: string;
  name: string;
  salesText: string;
  price: number;
  quantity: number;
};

type WebshopOrder = {
  orderId: string;
  orderNumber: string;
  products: WebshopProduct[];
  totalAmount: number;
  status: string;
  vorkasse: boolean;
  createdAt: Date;
  deliveryAddress: string;
  billingAddress?: string;
};

// Initialer Zustand
const initialState = {
  ordersPickShop: [] as WebshopOrder[],
  ordersPackShop: [] as WebshopOrder[],
  ordersPickUpShop: [] as WebshopOrder[],
  ordersHistoryShop: [] as WebshopOrder[],
  ordersReturnShop: [] as WebshopOrder[],
  fetchOrdersShop: async (status: string) => { },
  updateOrders: (status: string) => { },
};

type OrderWebshopProviderProps = {
  children: ReactNode;
};

const OrderWebshopContext = createContext(initialState);

export const OrderWebshopProvider: React.FC<OrderWebshopProviderProps> = ({
  children,
}) => {
  const sendRequest = useAuthRequest();
  const [ordersPickShop, setOrdersPickShop] = useState<WebshopOrder[]>([]);
  const [ordersPackShop, setOrdersPackShop] = useState<WebshopOrder[]>([]);
  const [ordersPickUpShop, setOrdersPickUpShop] = useState<WebshopOrder[]>(
    []
  );
  const [ordersHistoryShop, setOrdersHistoryShop] = useState<WebshopOrder[]>(
    []
  );
  const [ordersReturnShop, setOrdersReturnShop] = useState<WebshopOrder[]>(
    []
  );
  const [isDataLoaded, setIsDataLoaded] = useState<Record<string, boolean>>({
    pending: false,
    picked: false,
    packed: false,
    sent: false,
    canceled: false,
  });
  const oneMonthsAgo = subMonths(new Date(), 1);

  const filterReturnOrders = (orders: WebshopOrder[]) => {
    const oneMonthsAgo = subMonths(new Date(), 1);
    return orders.filter((order) => {
      const orderDate = parseISO(order.createdAt.toString());
      return orderDate > oneMonthsAgo && order.status === "returned";
    });
  };

  const fetchOrdersShop = async (status: string) => {
    if (isDataLoaded[status]) {
      return;
    }

    try {
      const response = await sendRequest(`/api/webshop/orders`, "get");

      if (response.data && Array.isArray(response.data)) {
        const filteredOrders = response.data.filter((order: WebshopOrder) => {
          if (status === "pending") {
            return order.status === "pending";
          } else if (status === "picked") {
            return order.status === "picked";
          } else if (status === "packed") {
            return order.status === "packed";
          } else if (status === "sent") {
            return ["sent", "canceled"].includes(order.status);
          } else if (status === "returned") {
            return order.status === "returned";
          }
          return false;
        });

        switch (status) {
          case "pending":
            setOrdersPickShop(filteredOrders);
            break;
          case "picked":
            setOrdersPackShop(filteredOrders);
            break;
          case "packed":
            setOrdersPickUpShop(filteredOrders);
            break;
          case "sent":
            setOrdersHistoryShop(filteredOrders);
            break;
          case "returned":
            setOrdersReturnShop(filteredOrders);
            break;
          default:
            console.error("Unbekannter Bestellstatus:", status);
        }
      } else {
        console.error(
          "Unerwartetes Format der Antwort:",
          response.data
        );
      }
    } catch (error) {
      console.error(
        `Fehler beim Abrufen von Bestellungen (${status}):`,
        error
      );
    }

    setIsDataLoaded((prevState) => ({ ...prevState, [status]: true }));
  };

  const updateOrders = (status: string) => {
    fetchOrdersShop(status);
  };

  return (
    <OrderWebshopContext.Provider
      value={{
        ordersPickShop,
        ordersPackShop,
        ordersPickUpShop,
        ordersHistoryShop,
        ordersReturnShop,
        fetchOrdersShop,
        updateOrders,
      }}
    >
      {children}
    </OrderWebshopContext.Provider>
  );
};

// Hook für den Zugriff auf den Context
export const useOrdersShop = () => useContext(OrderWebshopContext);
