import { Badge } from "src/components/ui/badge";
import { Button } from "src/components/ui/button";
import {
	Drawer,
	DrawerClose,
	DrawerContent,
	DrawerDescription,
	DrawerFooter,
	DrawerHeader,
	DrawerTitle,
	DrawerTrigger,
} from "src/components/ui/drawer";
import { ScrollBar } from "../../../components/ui/scroll-area";
import { Table, TableHeader, TableRow, TableHead, TableBody, TableCell } from "../../../components/ui/table";
import { ScrollArea } from "../../../components/ui/scroll-area";
import { useProductsAEB } from "../../helper/ProductContextAEB";
import { Pencil1Icon, Pencil2Icon, UploadIcon } from "@radix-ui/react-icons";
import { useEffect, useState } from "react";

const ProductManufacturer: React.FC = () => {
	const { editProduct, allBrands } = useProductsAEB();

	const [manufacturerInfo, setManufacturerInfo] = useState<string>("");

	const handleManufacturerChange = (manufacturerInfoUserQuery: string, brand: string) => {
		editProduct(manufacturerInfoUserQuery, brand);
	};

	const adjustTextareaHeight = (textarea: any) => {
		textarea.style.height = "auto"; // Reset the height to allow it to shrink if necessary
		textarea.style.height = `${textarea.scrollHeight}px`; // Set height based on content
	};

	return (
		<section className="w-full h-full p-8">
			<>
				<ScrollArea className="w-full whitespace-nowrap rounded-md border">
					<ScrollBar orientation="horizontal" />
					<Table className="px-8 z-40">
						<TableHeader className="sticky">
							<TableRow>
								<TableHead className="w-full">Marke</TableHead>
								<TableHead className="w-full">Aktionen</TableHead>
							</TableRow>
						</TableHeader>
						<TableBody>
							{Array.isArray(allBrands) && allBrands.length > 0 ? (
								allBrands.map((brand) => (
									<TableRow key={brand.id} className="dark:text-white">
										<TableCell>{brand.name}</TableCell>
										<TableCell className="h-full flex items-center justify-center gap-2">
											<Drawer>
												<DrawerTrigger asChild className="w-12 h-12">
													<Button variant="outline">
														<Pencil2Icon className="w-5 h-5" />
													</Button>
												</DrawerTrigger>
												<DrawerContent className="h-[95vh] px-12 z-[90]">
													<DrawerHeader className="flex flex-col">
														<DrawerTitle className="text-3xl flex flex-col items-start justify-start p-0 m-0">
															Kontaktdaten des Herstellers
														</DrawerTitle>
													</DrawerHeader>
													<ScrollArea>
														<ScrollBar orientation="vertical"></ScrollBar>
														<div>
															<section className="grid grid-cols-4 p-4 mt-8">
																<div className="col-span-1 w-full flex flex-col items-start justify-start">
																	{brand.name}
																	<span className="my-5 w-[10ch] h-[1px] bg-border"></span>
																	{brand.manufacturerInfo}
																</div>
																<textarea
																	id="message"
																	className="col-span-3 w-full min-h-[20ch] block p-2.5 text-sm text-gray-900 bg-background rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-background dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 resize-none"
																	placeholder="Geben Sie die Herstellerinformationen hier ein..."
																	onChange={(e) => {
																		setManufacturerInfo(e.target.value);
																		adjustTextareaHeight(e.target);
																	}}
																/>
															</section>
														</div>
													</ScrollArea>
													<DrawerFooter className="flex flex-row items-end justify-end">
														<DrawerClose className="flex items-center justify-center gap-6">
															<Button
																variant="default"
																className="flex gap-3"
																onClick={() =>
																	handleManufacturerChange(
																		manufacturerInfo,
																		brand.name
																	)
																}
															>
																<UploadIcon />
																Informationen speichern
															</Button>
															<Button variant="outline">Schließen</Button>
														</DrawerClose>
													</DrawerFooter>
												</DrawerContent>
											</Drawer>
										</TableCell>
									</TableRow>
								))
							) : (
								<TableRow>
									<TableCell colSpan={8} className="text-center py-12 text-xl">
										Es wurden keine Produkte gefunden
									</TableCell>
								</TableRow>
							)}
						</TableBody>
					</Table>
					<ScrollBar orientation="horizontal" />
				</ScrollArea>
			</>
		</section>
	);
};

export default ProductManufacturer;
