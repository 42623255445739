import { Tabs, TabsContent, TabsList, TabsTrigger } from "src/components/ui/tabs";
import WebshopDashboard from "../../components/dashboard/WebshopDashboard";
import OttoDashboard from "../../components/dashboard/OttoDashboard";

export default function Dashboard() {
	return (
		<section className="w-full h-full p-8">
			<Tabs defaultValue="otto" className="w-full flex flex-col items-start justify-center">
				<TabsList className="p-1 w-max h-auto bg-transparent border-[1px] border-border mb-8">
					<TabsTrigger value="webshop" className="text-2xl">
						Webshop
					</TabsTrigger>
					<TabsTrigger value="otto" className="text-2xl">
						Otto
					</TabsTrigger>
				</TabsList>
				<TabsContent value="webshop" className="w-full">
					<WebshopDashboard />
				</TabsContent>
				<TabsContent value="otto" className="w-full">
					<OttoDashboard />
				</TabsContent>
			</Tabs>
		</section>
	);
}
