import React, { createContext, useState, PropsWithChildren } from "react";
import axios from "axios";

interface FormattedSalesData {
	month: string;
	totalSales: number;
	orderCount: number;
	averageOrderValue: number;
	orderCountChangePercent?: number | null;
	totalSalesChangePercent?: number | null;
}

interface WebshopProduct {
	articleNr: number;
	ean: string;
	mainImage: string;
	productName: string;
	salesText: string;
	totalSales: number;
	unitsSold: number;
}

interface FormattedSalesDataYearly {
	year: string;
	totalSales: number;
	orderCount: number;
	averageOrderValue: number;
	orderCountChangePercent?: number | null;
	totalSalesChangePercent?: number | null;
}

interface AnalyticsContextType {
	monthlySalesWebshop: FormattedSalesData[] | undefined;
	yearlySalesWebshop: FormattedSalesDataYearly[] | undefined;
	topProductsWebshop: WebshopProduct[] | undefined;
	fetchMonthlyWebshopSales: (startMonth: string, endMonth: string) => Promise<void>;
	fetchYearlyWebshopSales: (startYear: string, endYear: string) => Promise<void>;
	fetchTopWebshopProducts: (limit?: number) => Promise<void>;
}

export const AnalyticsWebshopContext = createContext<AnalyticsContextType>({} as AnalyticsContextType);

export const AnalyticsWebshopProvider: React.FC<PropsWithChildren<{}>> = ({ children }) => {
	const [monthlySalesWebshop, setMonthlySalesWebshop] = useState<FormattedSalesData[] | undefined>(undefined);
	const [yearlySalesWebshop, setYearlySalesWebshop] = useState<FormattedSalesDataYearly[] | undefined>(undefined);
	const [topProductsWebshop, setTopProductsWebshop] = useState<WebshopProduct[] | undefined>(undefined);

	function transformSalesDataMonthly(data: FormattedSalesData[]): FormattedSalesData[] {
		return data.map((item: FormattedSalesData) => ({
			month: item.month,
			totalSales: item.totalSales,
			orderCount: item.orderCount,
			averageOrderValue: item.averageOrderValue,
			orderCountChangePercent: item.orderCountChangePercent ?? 0,
			totalSalesChangePercent: item.totalSalesChangePercent ?? 0,
		}));
	}

	function transformSalesDataYearly(data: FormattedSalesDataYearly[]): FormattedSalesDataYearly[] {
		return data.map((item: FormattedSalesDataYearly) => ({
			averageOrderValue: item.averageOrderValue,
			orderCount: item.orderCount,
			totalSales: item.totalSales,
			year: item.year,
			orderCountChangePercent: item.orderCountChangePercent ?? 0,
			totalSalesChangePercent: item.totalSalesChangePercent ?? 0,
		}));
	}

	async function fetchMonthlyWebshopSales(startMonth: string, endMonth: string) {
		try {
			const token = localStorage.getItem("authToken");
			const response = await axios.get("/api/webshop/analytics/monthly-sales", {
				params: { startMonth, endMonth },
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});

			if (response.status === 200) {
				const salesData = transformSalesDataMonthly(response.data);
				setMonthlySalesWebshop(salesData);
				console.log(response.data);
			} else {
				console.error("Fehler beim Abrufen der monatlichen Verkaufsdaten:", response.status);
			}
		} catch (error) {
			console.error("Fehler beim Abrufen der monatlichen Verkaufsdaten:", error);
		}
	}

	async function fetchYearlyWebshopSales(startYear: string, endYear: string) {
		try {
			const token = localStorage.getItem("authToken");
			const response = await axios.get("/api/webshop/analytics/yearly-sales", {
				params: { startYear, endYear },
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});

			if (response.status === 200) {
				const salesData = transformSalesDataYearly(response.data);
				setYearlySalesWebshop(salesData);
				console.log(response.data);
			} else {
				console.error("Fehler beim Abrufen der monatlichen Verkaufsdaten:", response.status);
			}
		} catch (error) {
			console.error("Fehler beim Abrufen der monatlichen Verkaufsdaten:", error);
		}
	}

	async function fetchTopWebshopProducts(limit: number = 10) {
		try {
			const response = await axios.get("/api/webshop/analytics/top-products", {
				params: { limit },
				headers: {
					Authorization: `Bearer ${localStorage.getItem("authToken")}`,
				},
			});

			if (response.status === 200) {
				setTopProductsWebshop(response.data);
				console.log(response.data);
			} else {
				console.error("Fehler beim Abrufen der Top-Produkte:", response.status);
			}
		} catch (error) {
			console.error("Fehler beim Abrufen der Top-Produkte:", error);
		}
	}

	return (
		<AnalyticsWebshopContext.Provider
			value={{
				monthlySalesWebshop,
				yearlySalesWebshop,
				topProductsWebshop,
				fetchMonthlyWebshopSales,
				fetchYearlyWebshopSales,
				fetchTopWebshopProducts,
			}}
		>
			{children}
		</AnalyticsWebshopContext.Provider>
	);
};
