import { effect, signal } from "@preact/signals-react";
import { NavLink, useNavigate } from "react-router-dom";
import { useOrdersShop } from "../../helper/OrderWebshopContext";
import OrderCardWebshop from "../../components/cards/OrderCardWebshop";
import useAuthRequest from "../../../utils/useAuthRequest";
import { getUserRoleFromToken, isTokenExpired } from "../../../utils/auth";

export default function OrderTableReturned() {
	const sendRequest = useAuthRequest();
	const navigate = useNavigate();
	const token = localStorage.getItem("authToken");
	const userRole = getUserRoleFromToken();
	const { ordersReturnShop, fetchOrdersShop } = useOrdersShop();

	effect(() => {
		if (
			!token ||
			!userRole ||
			isTokenExpired(token) ||
			userRole !== "bucher"
		) {
			navigate("/login");
		}
		fetchOrdersShop("returned");
		const intervalId = setInterval(() => {
			fetchOrdersShop("returned");
		}, 1000);

		// Cleanup-Funktion
		return () => clearInterval(intervalId);
	});

	const handleReturnOrder = async (orderId: string) => {
		try {
			/* const response = await sendRequest(
        `/api/webshop/acceptance/${orderId}`,
        "post"
      );
      if (response.status === 200) {
        console.log(response.data.message);
      } else {
        throw new Error("Fehler bei der Annahme der Retoure");
      } */
		} catch (error) {
			console.error("Fehler bei der Annahme der Retoure:", error);
		}
	};

	const handleDenyReturn = async (
		orderId: string,
		reason: string,
		condition: string
	) => {
		try {
			/* const response = await sendRequest(
        `/api/webshop/rejection/${orderId}`,
        "post",
        { reason, condition }
      );
      if (response.status === 200) {
        console.log(response.data.message);
      } else {
        throw new Error("Fehler bei der Ablehnung der Retoure");
      } */
		} catch (error) {
			console.error("Fehler bei der Ablehnung der Retoure:", error);
		}
	};

	return (
		<>
			<div className="text-gray-900 dark:text-gray-50 flex flex-col gap-4 p-6">
				{ordersReturnShop.length > 0 ? (
					ordersReturnShop.map((order) => (
						<OrderCardWebshop
							key={order.orderId}
							order={order}
							onReturnOrder={handleReturnOrder}
							onDenyReturn={handleDenyReturn}
						/>
					))
				) : (
					<div className="flex flex-col items-start justify-start bg-gray-300 dark:bg-sidebarGray-700 rounded-lg p-6">
						<h1 className="font-bold text-3xl">
							Es wurde Retouren gefunden.
						</h1>
					</div>
				)}
			</div>
		</>
	);
}
