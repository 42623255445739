import { ImageIcon, ValueNoneIcon } from "@radix-ui/react-icons";
import { EqualIcon, Package2, RocketIcon, TrendingDownIcon, TrendingUp, TrendingUpIcon } from "lucide-react";
import { useContext, useEffect, useState } from "react";
import {
	Bar,
	BarChart,
	CartesianGrid,
	Cell,
	Label,
	Pie,
	PieChart,
	PolarGrid,
	PolarRadiusAxis,
	RadialBar,
	RadialBarChart,
	XAxis,
} from "recharts";
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "src/components/ui/card";
import {
	ChartConfig,
	ChartContainer,
	ChartLegend,
	ChartLegendContent,
	ChartTooltip,
	ChartTooltipContent,
} from "src/components/ui/chart";
import { AnalyticsContext } from "../../helper/AnalyticsContext";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "src/components/ui/select";

function formatDate(date: Date): string {
	const day = String(date.getDate()).padStart(2, "0");
	const month = String(date.getMonth() + 1).padStart(2, "0");
	const year = date.getFullYear();
	return `${day}.${month}.${year}`;
}

function formatMonthYear(date: Date): string {
	const month = String(date.getMonth() + 1).padStart(2, "0");
	const year = date.getFullYear();
	return `${month}.${year}`;
}

function formatYear(date: Date): string {
	const year = date.getFullYear();
	return `${year}`;
}

export default function OttoDashboard() {
	const { fetchMonthlyOttoSales, fetchTopProducts, fetchYearlyOttoSales, monthlySales, topProducts, yearlySales } =
		useContext(AnalyticsContext);

	type ConfigKey = keyof typeof chartConfigs;
	const currentYear = new Date().getFullYear();
	const years = Array.from({ length: 10 }, (_, i) => currentYear - i); // Dropdown für die letzten 10 Jahre
	const months = [
		{ label: "Ganzes Jahr", startDate: new Date(currentYear, 0), endDate: new Date(currentYear, 11) },
		{ label: "Januar", startDate: new Date(currentYear, 0), endDate: new Date(currentYear, 0) },
		{ label: "Februar", startDate: new Date(currentYear, 1), endDate: new Date(currentYear, 1) },
		{ label: "März", startDate: new Date(currentYear, 2), endDate: new Date(currentYear, 2) },
		{ label: "April", startDate: new Date(currentYear, 3), endDate: new Date(currentYear, 3) },
		{ label: "Mai", startDate: new Date(currentYear, 4), endDate: new Date(currentYear, 4) },
		{ label: "Juni", startDate: new Date(currentYear, 5), endDate: new Date(currentYear, 5) },
		{ label: "Juli", startDate: new Date(currentYear, 6), endDate: new Date(currentYear, 6) },
		{ label: "August", startDate: new Date(currentYear, 7), endDate: new Date(currentYear, 7) },
		{ label: "September", startDate: new Date(currentYear, 8), endDate: new Date(currentYear, 8) },
		{ label: "Oktober", startDate: new Date(currentYear, 9), endDate: new Date(currentYear, 9) },
		{ label: "November", startDate: new Date(currentYear, 10), endDate: new Date(currentYear, 10) },
		{ label: "Dezember", startDate: new Date(currentYear, 11), endDate: new Date(currentYear, 11) },
	];

	const [selectedYear, setSelectedYear] = useState(currentYear);
	const [selectedMonthIndex, setSelectedMonthIndex] = useState(0);
	const [selectedConfig, setSelectedConfig] = useState<ConfigKey>("ordersReturns");

	function formatPrice(value: number): string {
		return (
			value
				.toFixed(2)
				.replace(".", ",")
				.replace(/\B(?=(\d{3})+(?!\d))/g, ".") + " €"
		);
	}

	useEffect(() => {
		const formattedYear = formatYear(new Date(selectedYear, 0));
		fetchTopProducts();
		if (selectedMonthIndex === 0) {
			fetchYearlyOttoSales(formattedYear, formattedYear);
			fetchMonthlyOttoSales(`01.${selectedYear}`, `12.${selectedYear}`);
		} else {
			const selectedMonth = months[selectedMonthIndex];
			const formattedMonthlyStartDate = formatMonthYear(selectedMonth.startDate);
			const formattedMonthlyEndDate = formatMonthYear(selectedMonth.endDate);
			fetchMonthlyOttoSales(formattedMonthlyStartDate, formattedMonthlyEndDate);
		}
	}, [selectedYear, selectedMonthIndex]);

	const salesPieChartTransformedData = [
		{
			sales: yearlySales && yearlySales[0].actualSales,
			fill: "var(--color-actual)",
			title: "Tatsächliche Verkäufe",
		},
		{
			sales: yearlySales && yearlySales[0].totalSales - yearlySales[0].actualSales,
			fill: "var(--color-total)",
			title: "Versäumte Verkäufe durch Retouren",
		},
	];

	const salesPieChartTransformedDataMonthly = [
		{
			sales: monthlySales && monthlySales[0].actualSales,
			fill: "var(--color-actual)",
			title: "Tatsächliche Verkäufe",
		},
		{
			sales: monthlySales && monthlySales[0].totalSales - monthlySales[0].actualSales,
			fill: "var(--color-total)",
			title: "Versäumte Verkäufe durch Retouren",
		},
	];

	const colorPalette = [
		"#a3c9a8",
		"#f2c1b6",
		"#b0b3af",
		"#f4a261",
		"#e76f51",
		"#7acec2",
		"#e6dfcc",
		"#412517",
		"#a75f3b",
		"#f58b57",
		"#19270d",
		"#25591f",
		"#818c3c",
		"#72601b",
		"#593a0e",
	];
	const getColorFromPalette = (index: number) => colorPalette[index % colorPalette.length];

	const salesWithColors = monthlySales
		? monthlySales.map((entry, index) => ({
				...entry,
				color: getColorFromPalette(index),
		  }))
		: [];

	const salesWithColorsPie = salesPieChartTransformedData
		? salesPieChartTransformedData.map((entry, index) => ({
				...entry,
				color: getColorFromPalette(index),
		  }))
		: [];

	const salesWithColorsPieMonthly = salesPieChartTransformedDataMonthly
		? salesPieChartTransformedDataMonthly.map((entry, index) => ({
				...entry,
				color: getColorFromPalette(index),
		  }))
		: [];

	const handleMonthChange = (value: string) => {
		setSelectedMonthIndex(parseInt(value));
	};

	const handleYearChange = (value: string) => {
		setSelectedYear(parseInt(value));
	};

	const chartConfigSalesPie = {
		actualSales: {
			label: "tatsächliche Verkäufe",
			color: "#2563eb",
		},
		totalSales: {
			label: "potenzielle Verkäufe",
			color: "#60a5fa",
		},
	} satisfies ChartConfig;

	const chartConfigOrderReturn = {
		orderCount: {
			label: "Bestellungen",
			color: "#2563eb",
		},
		returnCount: {
			label: "Retouren",
			color: "#60a5fa",
		},
	} satisfies ChartConfig;

	const chartConfigSalesWinnings = {
		totalSales: {
			label: "potenzielle Verkäufe",
			color: "#2563eb",
		},
		actualSales: {
			label: "tatsächliche  Verkäufe",
			color: "#60a5fa",
		},
	} satisfies ChartConfig;

	const chartConfigs = {
		ordersReturns: chartConfigOrderReturn,
		salesWinnings: chartConfigSalesWinnings,
	};

	const handleSelectChange = (value: ConfigKey) => {
		setSelectedConfig(value);
	};

	return (
		<>
			<div className="w-full flex flex-col lg:flex-row items-start lg:items-center justify-start border-b-[1px] border-border py-2">
				<label htmlFor="monthSelect" className="mr-2">
					Monat auswählen:
				</label>
				<Select onValueChange={handleMonthChange} defaultValue="0">
					<SelectTrigger className="w-[180px]">
						<SelectValue placeholder="Monat auswählen" />
					</SelectTrigger>
					<SelectContent>
						{months.map((month, index) => (
							<SelectItem key={index} value={index.toString()}>
								{month.label}
							</SelectItem>
						))}
					</SelectContent>
				</Select>

				<label htmlFor="yearSelect" className="ml-4 mr-2">
					Jahr auswählen:
				</label>
				<Select onValueChange={handleYearChange} defaultValue={currentYear.toString()}>
					<SelectTrigger className="w-[180px]">
						<SelectValue placeholder="Jahr auswählen" />
					</SelectTrigger>
					<SelectContent>
						{years.map((year) => (
							<SelectItem key={year} value={year.toString()}>
								{year}
							</SelectItem>
						))}
					</SelectContent>
				</Select>
			</div>
			<h1 className="my-12 text-5xl font-semibold">Otto Anayltics Dashboard</h1>
			{selectedMonthIndex === 0
				? yearlySales &&
				  yearlySales.map((yearly) => (
						<div>
							<section className="mt-6 flex md:grid lg:grid-cols-2 xl:flex flex-col xl:flex-row xl:items-center xl:justify-between w-full mb-6 gap-6">
								<Card className="w-full">
									<CardHeader>
										<CardTitle className="flex items-center justify-between">
											<span className="text-base text-gray-500">
												Gesamtumsatz (ohne Retouren)
											</span>
											<RocketIcon className="w-6 h-6 text-gray-500" />
										</CardTitle>
									</CardHeader>
									<CardContent>
										<p className="text-4xl font-bold">{formatPrice(yearly.actualSales)}</p>
									</CardContent>
									<CardFooter className="flex justify-start gap-2">
										{yearly.actualSalesChangePercent === 0 ? (
											<>
												<p className="text-gray-400">Keine Änderung zum Vorjahr</p>
											</>
										) : yearly.actualSalesChangePercent < 0 ? (
											<>
												<span className="text-red-400">{yearly.actualSalesChangePercent}%</span>
												<p className="text-red-400">zum Vorjahr</p>
												<TrendingDownIcon className="text-red-400 w-4 h-4" />
											</>
										) : (
											<>
												<span className="text-green-400">
													+{yearly.actualSalesChangePercent}%
												</span>
												<p className="text-green-400">zum Vorjahr</p>
												<TrendingUpIcon className="text-green-400 w-4 h-4" />
											</>
										)}
									</CardFooter>
								</Card>
								<Card className="w-full">
									<CardHeader>
										<CardTitle className="flex items-center justify-between">
											<span className="text-base text-gray-500">Gesamte Bestellungen</span>
											<Package2 className="w-6 h-6 text-gray-500" />
										</CardTitle>
									</CardHeader>
									<CardContent>
										<p className="text-4xl font-bold">{yearly.orderCount}</p>
									</CardContent>
									<CardFooter className="flex justify-start gap-2">
										{yearly.orderCountChangePercent === 0 ? (
											<>
												<p className="text-gray-400">Keine Änderung zum Vorjahr</p>
											</>
										) : yearly.orderCountChangePercent < 0 ? (
											<>
												<span className="text-red-400">{yearly.orderCountChangePercent}%</span>
												<p className="text-red-400">zum Vorjahr</p>
												<TrendingDownIcon className="text-red-400 w-4 h-4" />
											</>
										) : (
											<>
												<span className="text-green-400">
													+{yearly.orderCountChangePercent}%
												</span>
												<p className="text-green-400">zum Vorjahr</p>
												<TrendingUpIcon className="text-green-400 w-4 h-4" />
											</>
										)}
									</CardFooter>
								</Card>
								<Card className="w-full">
									<CardHeader>
										<CardTitle className="flex items-center justify-between">
											<span className="text-base text-gray-500">
												Durchschnittliche Retourenquote
											</span>
											<ValueNoneIcon className="w-6 h-6 text-gray-500" />
										</CardTitle>
									</CardHeader>
									<CardContent>
										<p className="text-4xl font-bold">{yearly.returnRate}%</p>
									</CardContent>
									<CardFooter className="flex justify-start gap-2">
										{yearly.returnCountChangePercent === 0 ? (
											<>
												<p className="text-gray-400">Keine Änderung zum Vorjahr</p>
											</>
										) : yearly.returnCountChangePercent < 0 ? (
											<>
												<span className="text-green-400">
													{yearly.returnCountChangePercent}%
												</span>
												<p className="text-green-400">zum Vorjahr</p>
												<TrendingDownIcon className="text-green-400 w-4 h-4" />
											</>
										) : (
											<>
												<span className="text-red-400">
													+{yearly.returnCountChangePercent}%
												</span>
												<p className="text-red-400">zum Vorjahr</p>
												<TrendingUpIcon className="text-red-400 w-4 h-4" />
											</>
										)}
									</CardFooter>
								</Card>
								<Card className="w-full h-full">
									<CardHeader>
										<CardTitle className="flex items-center justify-between">
											<span className="text-base text-gray-500">
												Durchschnittlicher Bestellwert
											</span>
											<ValueNoneIcon className="w-6 h-6 text-gray-500" />
										</CardTitle>
									</CardHeader>
									<CardContent>
										<p className="text-4xl font-bold">{formatPrice(yearly.averageOrderValue)}</p>
									</CardContent>
									<CardFooter className="flex justify-between">
										<p className="text-gray-500">-</p>
									</CardFooter>
								</Card>
							</section>
							<h1 className="mt-12 text-4xl">Verkaufsstatistiken</h1>
							<div className="grid grid-cols-1 lg:grid-cols-2 w-full gap-6 mt-6">
								<div className="flex flex-col items-start justify-start">
									<Select value={selectedConfig} onValueChange={handleSelectChange}>
										<SelectTrigger className="border rounded p-2 mb-4 w-1/3">
											<SelectValue placeholder="Wähle eine Option" />
										</SelectTrigger>
										<SelectContent>
											<SelectItem value="ordersReturns">Bestellungen | Retouren</SelectItem>
											<SelectItem value="salesWinnings">
												potenzielle Verkäufe | Tatsächliche Verkäufe
											</SelectItem>
										</SelectContent>
									</Select>
									<div className="flex flex-col items-start justify-start border-[1px] border-gray-600 rounded-lg w-full h-full">
										<span className="text-gray-500 text-xl w-full rounded-t-lg p-3 border-b-gray-500 border-[1px]">
											Bestellungen | Retouren pro Monat
										</span>
										<ChartContainer config={chartConfigs[selectedConfig]} className="h-full w-full">
											<BarChart accessibilityLayer data={monthlySales}>
												<CartesianGrid vertical={false} />
												<XAxis
													dataKey="month"
													tickLine={false}
													tickMargin={10}
													axisLine={false}
													tickFormatter={(value) => value.slice(0, 3)}
												/>
												<ChartTooltip content={<ChartTooltipContent />} />
												<ChartLegend content={<ChartLegendContent />} />

												{selectedConfig === "ordersReturns" ? (
													<>
														<Bar
															dataKey="orderCount"
															fill={chartConfigs.ordersReturns.orderCount.color}
															radius={4}
														/>
														<Bar
															dataKey="returnCount"
															fill={chartConfigs.ordersReturns.returnCount.color}
															radius={4}
														/>
													</>
												) : (
													<>
														<Bar
															dataKey="totalSales"
															fill={chartConfigs.salesWinnings.totalSales.color}
															radius={4}
														/>
														<Bar
															dataKey="actualSales"
															fill={chartConfigs.salesWinnings.actualSales.color}
															radius={4}
														/>
													</>
												)}
											</BarChart>
										</ChartContainer>
									</div>
								</div>
								<div className="flex flex-col items-start justify-start w-full h-full">
									<Card className="flex flex-col w-full">
										<CardHeader className="items-center pb-0">
											<CardTitle>Verhältnis potenzielle und tatsächliche Umsätze</CardTitle>
											<CardDescription>{selectedYear}</CardDescription>
										</CardHeader>
										<h1 className="w-full text-center text-2xl font-semibold py-2">
											Gesamte potenzielle Umsätze: {formatPrice(yearly.totalSales)}
										</h1>
										<CardContent className="flex-1 pb-0">
											<ChartContainer
												config={chartConfigSalesPie}
												className="mx-auto aspect-square"
											>
												<PieChart>
													<ChartTooltip
														cursor={false}
														content={<ChartTooltipContent hideLabel />}
													/>
													<Pie data={salesWithColorsPie} dataKey="sales" nameKey="title">
														{salesWithColorsPie.map((entry, index) => (
															<Cell key={`cell-${index}`} fill={entry.color} />
														))}
													</Pie>
												</PieChart>
											</ChartContainer>
										</CardContent>
										<CardFooter className="flex-col gap-2 text-sm">
											<div className="flex items-center gap-2 font-medium leading-none">
												Umsatzsteigerung um {yearly.actualSalesChangePercent} % zum Vorjahr.
												<TrendingUp className="h-4 w-4" />
											</div>
											<div className="leading-none text-muted-foreground">
												Zeigt das Verhältnis zwischen potenziellem Umsatz ohne Retouren und
												tatsachlichen Verkäufen
											</div>
										</CardFooter>
									</Card>
								</div>
							</div>
							<h1 className="mt-12 text-4xl">Topseller des Jahres</h1>
							<div className="grid grid-cols-1 lg:grid-cols-3 w-full items-start justify-between mt-6 gap-6">
								<Card className="flex flex-col w-full h-full p-8">
									<CardHeader className="items-start p-0">
										<CardTitle className="text-xl text-gray-500">
											Meistverkaufte Warengruppe
										</CardTitle>
										<CardDescription></CardDescription>
									</CardHeader>
									<CardContent className="flex-1 p-0 border-t-[1px] border-border mt-8 pt-2">
										<div className="w-full text-4xl text-left flex items-center gap-2 font-medium leading-none">
											{yearly.mostSoldCategory}
										</div>
									</CardContent>
								</Card>
								<Card className="flex flex-col w-full h-full p-8">
									<CardHeader className="items-start p-0">
										<CardTitle className="text-xl text-gray-500">Meistverkauftes Produkt</CardTitle>
										<CardDescription></CardDescription>
									</CardHeader>
									<CardContent className="flex-1 p-0 border-t-[1px] border-border mt-8 pt-2">
										<div className="w-full text-4xl text-left flex items-center gap-2 font-medium leading-none">
											{yearly.mostSoldProduct}
										</div>
									</CardContent>
								</Card>
								<Card className="flex flex-col w-full h-full p-8">
									<CardHeader className="items-start p-0">
										<CardTitle className="text-xl text-gray-500">Anzahl Verkäufe</CardTitle>
										<CardDescription></CardDescription>
									</CardHeader>
									<CardContent className="flex-1 p-0 border-t-[1px] border-border mt-8 pt-2">
										<div className="w-full text-4xl text-left flex items-center gap-2 font-medium leading-none">
											{yearly.mostSoldProductUnitsSold}
										</div>
									</CardContent>
								</Card>
							</div>
							<div className="mt-6 grid grid-cols-1 gap-6">
								<Card className="w-full h-full">
									<CardHeader>Verkaufsschlager des Jahres {selectedYear}</CardHeader>
									<CardDescription className="px-6">
										Hier sehen die top 10 Bestellungen
									</CardDescription>
									<CardContent className="grid grid-cols-1 lg:grid-cols-2">
										{topProducts &&
											topProducts.map((top) => (
												<div className="flex items-start justify-center w-full mt-6 gap-4">
													{top.mainImage ? (
														<img
															src={`data:image/jpeg;base64,${top.mainImage}`}
															width={75}
															height={75}
															alt=""
														/>
													) : (
														<ImageIcon className="w-24 h-24 text-sidebarGray-500" />
													)}
													<div className="w-full flex-col items-start justify-center  text-left flex gap-4 font-medium leading-none">
														<p className="text-xs text-sidebarGray-500">{top.articleId}</p>
														<p>{top.title}</p>
														<p className="text-xs text-sidebarGray-500">
															Verkaufte Einheiten: {top.unitsSold}
														</p>
													</div>
												</div>
											))}
									</CardContent>
								</Card>
							</div>
							<h1 className="mt-12 text-4xl">Retouren</h1>
							<Card className="flex flex-col mt-6">
								<CardHeader className="items-center pb-0">
									<CardTitle>Anteil der Umsätze nach Monaten</CardTitle>
									<CardDescription>{selectedYear}</CardDescription>
									<h1 className="text-2xl font-bold">Gesamte Retouren {yearly.returnCount}</h1>
								</CardHeader>
								<CardContent className="flex-1 pb-0">
									<ChartContainer
										config={chartConfigOrderReturn}
										className="mx-auto aspect-square max-h-[500px]"
									>
										<PieChart>
											<ChartTooltip cursor={false} content={<ChartTooltipContent hideLabel />} />
											<Pie data={salesWithColors} dataKey="returnCount" nameKey="month">
												{salesWithColors.map((entry, index) => (
													<Cell key={`cell-${index}`} fill={entry.color} />
												))}
											</Pie>
										</PieChart>
									</ChartContainer>
								</CardContent>
							</Card>
						</div>
				  ))
				: monthlySales &&
				  monthlySales.map((monthly) => (
						<>
							<section className="mt-6 flex md:grid lg:grid-cols-2 xl:flex flex-col xl:flex-row xl:items-center xl:justify-between w-full mb-6 gap-6">
								<Card className="w-full">
									<CardHeader>
										<CardTitle className="flex items-center justify-between">
											<span className="text-base text-gray-500">
												Gesamtumsatz (ohne Retouren)
											</span>
											<RocketIcon className="w-6 h-6 text-gray-500" />
										</CardTitle>
									</CardHeader>
									<CardContent>
										<p className="text-4xl font-bold">{formatPrice(monthly.actualSales)}</p>
									</CardContent>
									<CardFooter className="flex justify-start gap-2">
										{monthly.actualSalesChangePercent === 0 ? (
											<>
												<p className="text-gray-400">Keine Änderung zum Vormonat</p>
											</>
										) : monthly.actualSalesChangePercent < 0 ? (
											<>
												<span className="text-red-400">
													{monthly.actualSalesChangePercent}%
												</span>
												<p className="text-red-400">zum Vormonat</p>
												<TrendingDownIcon className="text-red-400 w-4 h-4" />
											</>
										) : (
											<>
												<span className="text-green-400">
													+{monthly.actualSalesChangePercent}%
												</span>
												<p className="text-green-400">zum Vormonat</p>
												<TrendingUpIcon className="text-green-400 w-4 h-4" />
											</>
										)}
									</CardFooter>
								</Card>
								<Card className="w-full">
									<CardHeader>
										<CardTitle className="flex items-center justify-between">
											<span className="text-base text-gray-500">Gesamte Bestellungen</span>
											<Package2 className="w-6 h-6 text-gray-500" />
										</CardTitle>
									</CardHeader>
									<CardContent>
										<p className="text-4xl font-bold">{monthly.orderCount}</p>
									</CardContent>
									<CardFooter className="flex justify-start gap-2">
										{monthly.orderCountChangePercent === 0 ? (
											<>
												<p className="text-gray-400">Keine Änderung zum Vormonat</p>
											</>
										) : monthly.orderCountChangePercent < 0 ? (
											<>
												<span className="text-red-400">{monthly.orderCountChangePercent}%</span>
												<p className="text-red-400">zum Vormonat</p>
												<TrendingDownIcon className="text-red-400 w-4 h-4" />
											</>
										) : (
											<>
												<span className="text-green-400">
													+{monthly.orderCountChangePercent}%
												</span>
												<p className="text-green-400">zum Vormonat</p>
												<TrendingUpIcon className="text-green-400 w-4 h-4" />
											</>
										)}
									</CardFooter>
								</Card>
								<Card className="w-full">
									<CardHeader>
										<CardTitle className="flex items-center justify-between">
											<span className="text-base text-gray-500">
												Durchschnittliche Retourenquote
											</span>
											<ValueNoneIcon className="w-6 h-6 text-gray-500" />
										</CardTitle>
									</CardHeader>
									<CardContent>
										<p className="text-4xl font-bold">{monthly.returnRate}%</p>
									</CardContent>
									<CardFooter className="flex justify-start gap-2">
										{monthly.returnCountChangePercent === 0 ? (
											<>
												<p className="text-gray-400">Keine Änderung zum Vormonat</p>
											</>
										) : monthly.returnCountChangePercent < 0 ? (
											<>
												<span className="text-green-400">
													{monthly.returnCountChangePercent}%
												</span>
												<p className="text-green-400">zum Vormonat</p>
												<TrendingDownIcon className="text-green-400 w-4 h-4" />
											</>
										) : (
											<>
												<span className="text-red-400">
													+{monthly.returnCountChangePercent}%
												</span>
												<p className="text-red-400">zum Vormonat</p>
												<TrendingUpIcon className="text-red-400 w-4 h-4" />
											</>
										)}
									</CardFooter>
								</Card>
								<Card className="w-full h-full">
									<CardHeader>
										<CardTitle className="flex items-center justify-between">
											<span className="text-base text-gray-500">
												Durchschnittlicher Bestellwert
											</span>
											<ValueNoneIcon className="w-6 h-6 text-gray-500" />
										</CardTitle>
									</CardHeader>
									<CardContent>
										<p className="text-4xl font-bold">{formatPrice(monthly.averageOrderValue)}</p>
									</CardContent>
									<CardFooter className="flex justify-between">
										<p className="text-gray-500">-</p>
									</CardFooter>
								</Card>
							</section>
							<div className="grid grid-cols-1 lg:grid-cols-2 w-full gap-6 mt-6">
								<div className="flex flex-col items-start justify-start">
									<Select value={selectedConfig} onValueChange={handleSelectChange}>
										<SelectTrigger className="border rounded p-2 mb-4 w-1/3">
											<SelectValue placeholder="Wähle eine Option" />
										</SelectTrigger>
										<SelectContent>
											<SelectItem value="ordersReturns">Bestellungen | Retouren</SelectItem>
											<SelectItem value="salesWinnings">
												potenzielle Verkäufe | Tatsächliche Verkäufe
											</SelectItem>
										</SelectContent>
									</Select>
									<div className="flex flex-col items-start justify-start border-[1px] border-gray-600 rounded-lg w-full h-full">
										<span className="text-gray-500 text-xl w-full rounded-t-lg p-3 border-b-gray-500 border-[1px]">
											Bestellungen | Retouren pro Monat
										</span>
										<ChartContainer config={chartConfigs[selectedConfig]} className="h-full w-full">
											<BarChart accessibilityLayer data={monthlySales}>
												<CartesianGrid vertical={false} />
												<XAxis
													dataKey="month"
													tickLine={false}
													tickMargin={10}
													axisLine={false}
													tickFormatter={(value) => value.slice(0, 3)}
												/>
												<ChartTooltip content={<ChartTooltipContent />} />
												<ChartLegend content={<ChartLegendContent />} />

												{selectedConfig === "ordersReturns" ? (
													<>
														<Bar
															dataKey="orderCount"
															fill={chartConfigs.ordersReturns.orderCount.color}
															radius={4}
														/>
														<Bar
															dataKey="returnCount"
															fill={chartConfigs.ordersReturns.returnCount.color}
															radius={4}
														/>
													</>
												) : (
													<>
														<Bar
															dataKey="totalSales"
															fill={chartConfigs.salesWinnings.totalSales.color}
															radius={4}
														/>
														<Bar
															dataKey="actualSales"
															fill={chartConfigs.salesWinnings.actualSales.color}
															radius={4}
														/>
													</>
												)}
											</BarChart>
										</ChartContainer>
									</div>
								</div>
								<div className="flex flex-col items-start justify-start w-full h-full">
									<Card className="flex flex-col w-full">
										<CardHeader className="items-center pb-0">
											<CardTitle>Verhältnis potenzielle und tatsächliche Umsätze</CardTitle>
											<CardDescription>{selectedYear}</CardDescription>
										</CardHeader>
										<h1 className="w-full text-center text-2xl font-semibold py-2">
											Gesamte potenzielle Umsätze: {formatPrice(monthly.totalSales)}
										</h1>
										<CardContent className="flex-1 pb-0">
											<ChartContainer
												config={chartConfigSalesPie}
												className="mx-auto aspect-square max-h-[600px]"
											>
												<PieChart>
													<ChartTooltip
														cursor={false}
														content={<ChartTooltipContent hideLabel />}
													/>
													<Pie
														data={salesWithColorsPieMonthly}
														dataKey="sales"
														nameKey="title"
													>
														{salesWithColorsPieMonthly.map((entry, index) => (
															<Cell key={`cell-${index}`} fill={entry.color} />
														))}
													</Pie>
												</PieChart>
											</ChartContainer>
										</CardContent>
										<CardFooter className="flex-col gap-2 text-sm">
											<div className="flex items-center gap-2 font-medium leading-none">
												Umsatzsteigerung um {monthly.actualSalesChangePercent} % im vergleich
												zum Vorjahr.
												<TrendingUp className="h-4 w-4" />
											</div>
											<div className="leading-none text-muted-foreground">
												Zeigt das Verhältnis zwischen potenziellem Umsatz ohne Retouren und
												tatsachlichen Verkäufen
											</div>
										</CardFooter>
									</Card>
								</div>
							</div>
							<h1 className="mt-6 text-4xl">Topseller des Monats</h1>
							<div className="grid grid-cols-1 lg:grid-cols-3 w-full items-start justify-between mt-6 gap-4">
								<Card className="flex flex-col w-full h-full p-8">
									<CardHeader className="items-start p-0">
										<CardTitle className="text-xl text-gray-500">
											Meistverkaufte Warengruppe
										</CardTitle>
										<CardDescription></CardDescription>
									</CardHeader>
									<CardContent className="flex-1 p-0 border-t-[1px] border-border mt-8 pt-2">
										<div className="w-full text-4xl text-left flex items-center gap-2 font-medium leading-none">
											{monthly.mostSoldCategory}
										</div>
									</CardContent>
								</Card>
								<Card className="flex flex-col w-full h-full p-8">
									<CardHeader className="items-start p-0">
										<CardTitle className="text-xl text-gray-500">Meistverkauftes Produkt</CardTitle>
										<CardDescription></CardDescription>
									</CardHeader>
									<CardContent className="flex-1 p-0 border-t-[1px] border-border mt-8 pt-2">
										<div className="w-full text-4xl text-left flex items-center gap-2 font-medium leading-none">
											{monthly.mostSoldProduct}
										</div>
									</CardContent>
								</Card>
								<Card className="flex flex-col w-full h-full p-8">
									<CardHeader className="items-start p-0">
										<CardTitle className="text-xl text-gray-500">
											Gesamtumsatz (ohne Retouren)
										</CardTitle>
										<CardDescription></CardDescription>
									</CardHeader>
									<CardContent className="flex-1 p-0 border-t-[1px] border-border mt-8 pt-2">
										<div className="w-full text-4xl text-left flex items-center gap-2 font-medium leading-none">
											{monthly.mostSoldProductUnitsSold}
										</div>
									</CardContent>
								</Card>
							</div>
						</>
				  ))}
		</>
	);
}
