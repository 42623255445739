import { effect, signal } from "@preact/signals-react";
import axios from "axios";
import useAuthRequest from "../../../../utils/useAuthRequest";
import "../styles/OttoStyles.css";
import { useNavigate } from "react-router-dom";
import { getUserRoleFromToken, isTokenExpired } from "../../../../utils/auth";
import OrderCard from "../../../components/cards/OrderCard";
import { useOrders } from "../../../helper/OrderContext";

type Dimension = {
	displayName: string;
	type: string;
	value: string;
};

type Product = {
	title: string;
	salesPrice: string;
	articleNumber: string;
	ean: string;
};

type Order = {
	id: string;
	orderNumber: string;
	orderDate: string;
	products: Product[];
	status?: string;
};

function OrderPickUpPage() {
	const sendRequest = useAuthRequest();
	const navigate = useNavigate();
	const token = localStorage.getItem("authToken");
	const userRole = getUserRoleFromToken();
	const { ordersPickUp, fetchOrders } = useOrders();

	effect(() => {
		if (
			!token ||
			!userRole ||
			isTokenExpired(token) ||
			userRole !== "bucher"
		) {
			navigate("/login");
		}
		fetchOrders("packed");
		const intervalId = setInterval(() => {
			fetchOrders("packed");
		}, 1000);

		// Cleanup-Funktion
		return () => clearInterval(intervalId);
	});

	/* const handleCompleteOrder = async (orderId: string) => {
    try {
      const response = await sendRequest(`/api/otto/mark-order-as-shipped/${orderId}`, 'patch', { status: 'shipped' });
      if (response.status === 200) {
      } else {
        throw new Error('Fehler beim Aktualisieren des Bestellstatus');
      }
    } catch (error) {
      console.error('Fehler beim Aktualisieren des Bestellstatus:', error);
    }
  }; */
	const handleCompleteOrder = async (orderId: string) => {
		try {
			const response = await sendRequest(
				`/api/otto/mark-order-as-shipped/${orderId}`,
				"post"
			);
			if (response.status === 200) {
			} else {
				throw new Error("Fehler beim Aktualisieren des Bestellstatus");
			}
		} catch (error) {
			console.error("Fehler beim Senden des Requests: ", error);
		}
	};

	return (
		<>
			<div className="text-gray-900 dark:text-gray-50 flex flex-col gap-4 p-6">
				{ordersPickUp.length > 0 ? (
					ordersPickUp.map((order) => (
						<OrderCard
							key={order.id}
							order={order}
							onCompleteOrder={handleCompleteOrder}
						/>
					))
				) : (
					<div className="flex flex-col items-start justify-start bg-gray-300 dark:bg-sidebarGray-700 rounded-lg p-6">
						<h1 className="font-bold text-3xl">
							Es wurde keine Bestellungen, bereit für Abholung
							gefunden.
						</h1>
					</div>
				)}
			</div>
		</>
	);
}

export default OrderPickUpPage;
