import React, { PropsWithChildren, createContext, useContext, useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

export interface SageImage {
	id: number;
	imageUrl: string;
	product: SageProduct;
	articleId: number;
}

export interface SageProduct {
	id: number;
	articleId: number;
	description: string;
	unit: string;
	searchTerm: string;
	additionalInfo: string;
	price: number;
	barcode: string;
	weight: number;
	weightUnit: string;
	basePriceUnit: string;
	available: number;
	productGroup: string;
	images: SageImage[];
	active: boolean;
	showPrice: boolean;
}

interface ProductContextType {
	productList: SageProduct[];
	productGroups: string[];
	fetchProducts: (
		page?: number,
		sortBy?: string,
		sortDirection?: string,
		productGroup?: string,
		sortStatus?: string
	) => Promise<void>;
	updateProductStatus: (articleId: number, active: boolean) => void;
	updatePriceStatus: (articleId: number, active: boolean) => void;
	deleteImage: (imageId: number, articleId: number) => void;
	searchProducts: (term: string, page?: number, sortBy?: string, sortDirection?: string) => void;
	uploadImages: (articleId: number, images: File[]) => Promise<void>;
	fetchProductGroups: () => void;
	totalPages: number;
	totalProducts: number;
	currentPage: number;
	setCurrentPage: (page: number) => void;
}

export const ProductContext = createContext<ProductContextType>({} as ProductContextType);

export const ProductProvider: React.FC<PropsWithChildren<object>> = ({ children }) => {
	const [productList, setProductList] = useState<SageProduct[]>([]);
	const [totalPages, setTotalPages] = useState<number>(1);
	const [totalProducts, setTotalProducts] = useState<number>(1);
	const [currentPage, setCurrentPage] = useState<number>(1);
	const [productGroups, setProductGroups] = useState<string[]>([]);
	const navigate = useNavigate();

	async function fetchProducts(
		page = 1,
		sortBy = "articleId",
		sortDirection = "ASC",
		productGroup: string | null = null,
		sortStatus: string | undefined = undefined
	) {
		try {
			const params: any = {
				page: page.toString(),
				sortBy,
				sortDirection,
				...(productGroup && { productGroup }),
			};

			if (sortStatus) {
				sortStatus.split("&").forEach((filter) => {
					const [field, value] = filter.split("=");
					if (field && value) {
						params[field] = value;
					}
				});
			}

			const token = localStorage.getItem("authToken");
			const response = await axios.get(`/api/sage/products`, {
				params,
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
			});

			if (response.status === 200) {
				setProductList(response.data.products);
				setTotalPages(response.data.totalPages);
				setTotalProducts(response.data.total);
			} else if (response.status === 401) {
				navigate("/login");
			} else {
				console.error("Unexpected status code:", response.status);
			}
		} catch (error) {
			console.error("Beim Laden der Produkte ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.");
		}
	}

	const updateProductStatus = async (articleId: number, active: boolean) => {
		try {
			const token = localStorage.getItem("authToken");
			const response = await axios.put(
				`/api/sage/product/${articleId}/status`,
				{ active },
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			);
			if (response.status === 200) {
				setProductList((prevList) =>
					prevList.map((product) => (product.articleId === articleId ? { ...product, active } : product))
				);
			} else {
				console.error("Error updating product status:", response.status);
			}
		} catch (error) {
			console.error("Error updating product status:", error);
		}
	};

	const updatePriceStatus = async (articleId: number, showPrice: boolean) => {
		try {
			const token = localStorage.getItem("authToken");
			const response = await axios.put(
				`/api/sage/product/${articleId}/show-price`,
				{ showPrice },
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			);
			if (response.status === 200) {
				setProductList((prevList) =>
					prevList.map((product) => (product.articleId === articleId ? { ...product, showPrice } : product))
				);
			} else {
				console.error("Error updating product status:", response.status);
			}
		} catch (error) {
			console.error("Error updating product status:", error);
		}
	};

	const deleteImage = async (imageId: number, articleId: number) => {
		try {
			const token = localStorage.getItem("authToken");
			const response = await axios.delete(`/api/sage/delete-image/${imageId}`, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});
			if (response.status === 200) {
				setProductList((prevList) =>
					prevList.map((product) =>
						product.articleId === articleId
							? {
									...product,
									images: product.images.filter((image) => image.id !== imageId),
							  }
							: product
					)
				);
			} else {
				console.error("Error deleting image:", response.status);
			}
		} catch (error) {
			console.error("Error deleting image:", error);
		}
	};

	async function searchProducts(term = "", page = 1, sortBy = "price", sortDirection = "ASC") {
		try {
			const token = localStorage.getItem("authToken");
			const params = {
				term,
				page: page.toString(),
				sortBy,
				sortDirection,
			};
			const response = await axios.get(`/api/sage/products/search`, {
				params,
				headers: {
					Authorization: `Bearer ${token}`,
					"Content-Type": "application/json",
				},
			});

			if (response.status === 200) {
				const data = response.data.products;
				const totalTemp = response.data.total;
				const totalPagesTemp = response.data.totalPages;
				const pageTemp = response.data.page;
				setProductList(data);
				setTotalPages(totalPagesTemp);
				setTotalProducts(totalTemp);
				setCurrentPage(pageTemp);
			}
		} catch (error) {
			console.error("Beim Suchen der Produkte ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.");
		}
	}

	const fetchProductGroups = async () => {
		try {
			const token = localStorage.getItem("authToken");
			const response = await axios.get(`/api/sage/product-groups`, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});
			if (response.status === 200) {
				const filteredData = response.data.filter((item: string) => item !== "");
				setProductGroups(filteredData);
			} else {
				console.error("Error fetching product groups:", response.status);
			}
		} catch (error) {
			console.error("Error fetching product groups:", error);
		}
	};

	const uploadImages = async (articleId: number, images: File[]) => {
		try {
			const token = localStorage.getItem("authToken");
			const formData = new FormData();
			images.forEach((image) => {
				formData.append("imageFiles", image);
			});
			formData.append("articleId", articleId.toString());

			const response = await axios.post("/api/sage/upload-images", formData, {
				headers: {
					"Content-Type": "multipart/form-data",
					Authorization: `Bearer ${token}`,
				},
			});

			if (response.status === 200) {
				const newUploadedImages = response.data.images.map((img: { id: number; imageUrl: string }) => ({
					id: img.id,
					url: img.imageUrl,
				}));
				setProductList((prevList) =>
					prevList.map((product) =>
						product.articleId === articleId
							? {
									...product,
									images: [...product.images, ...newUploadedImages],
							  }
							: product
					)
				);
			} else {
				console.error("Error uploading images:", response.status);
			}
		} catch (error) {
			console.error("Error uploading images:", error);
		}
	};

	return (
		<ProductContext.Provider
			value={{
				fetchProducts,
				productList,
				updateProductStatus,
				productGroups,
				updatePriceStatus,
				deleteImage,
				fetchProductGroups,
				searchProducts,
				uploadImages,
				totalPages,
				totalProducts,
				currentPage,
				setCurrentPage,
			}}
		>
			{children}
		</ProductContext.Provider>
	);
};

export const useProducts = () => useContext(ProductContext);
