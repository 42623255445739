import React, { createContext, useState, PropsWithChildren } from "react";
import axios, { AxiosResponse } from "axios";

interface AnalyticsContextType {
	monthlySales: SalesData[] | undefined;
	yearlySales: FormattedYearlySalesData[] | undefined;
	topProducts: TopSellingProduct[] | undefined;
	fetchMonthlyOttoSales: (startMonth: string, endMonth: string) => Promise<SalesData[] | void>;
	fetchYearlyOttoSales: (startYear: string, endYear: string) => Promise<FormattedYearlySalesData[] | void>;
	fetchTopProducts: (limit?: number) => Promise<TopSellingProduct[] | void>;
}

interface SalesData {
	month: string;
	totalSales: number;
	actualSales: number;
	orderCount: number;
	returnCount: number;
	returnRate: number;
	averageOrderValue: number;
	mostSoldCategory: string;
	mostSoldProduct: string;
	mostSoldProductUnitsSold: number;
	orderCountChangePercent: number;
	totalSalesChangePercent: number;
	returnCountChangePercent: number;
	actualSalesChangePercent: number;
}

interface FormattedYearlySalesData {
	year: string;
	totalSales: number;
	actualSales: number;
	orderCount: number;
	returnCount: number;
	returnRate: number;
	averageOrderValue: number;
	mostSoldCategory: string;
	mostSoldProduct: string;
	mostSoldProductUnitsSold: number;
	orderCountChangePercent: number;
	totalSalesChangePercent: number;
	returnCountChangePercent: number;
	actualSalesChangePercent: number;
}

interface TopSellingProduct {
	title: string;
	totalSales: number;
	unitsSold: number;
	articleId?: number;
	referenceNumber?: string;
	saleText?: string;
	brand?: string;
	price?: number;
	productGroup?: string;
	productType?: string;
	productLine?: string;
	mainImage?: string | null;
}

export const AnalyticsContext = createContext<AnalyticsContextType>({} as AnalyticsContextType);

export const AnalyticsProvider: React.FC<PropsWithChildren<{}>> = ({ children }) => {
	const [monthlySales, setMonthlySales] = useState<SalesData[] | undefined>(undefined);
	const [yearlySales, setYearlySales] = useState<FormattedYearlySalesData[] | undefined>(undefined);
	const [topProducts, setTopProducts] = useState<TopSellingProduct[] | undefined>(undefined);

	function transformSalesData(data: SalesData[]): SalesData[] {
		return data.map((item: SalesData) => ({
			actualSales: item.actualSales,
			averageOrderValue: item.averageOrderValue,
			month: item.month,
			mostSoldCategory: item.mostSoldCategory,
			mostSoldProduct: item.mostSoldProduct,
			mostSoldProductUnitsSold: item.mostSoldProductUnitsSold,
			orderCount: item.orderCount,
			returnCount: item.returnCount,
			returnRate: item.returnRate,
			totalSales: item.totalSales,
			actualSalesChangePercent: item.actualSalesChangePercent ?? 0,
			orderCountChangePercent: item.orderCountChangePercent ?? 0,
			returnCountChangePercent: item.returnCountChangePercent ?? 0,
			totalSalesChangePercent: item.totalSalesChangePercent ?? 0,
		}));
	}

	function transformSalesDataYearly(data: FormattedYearlySalesData[]): FormattedYearlySalesData[] {
		return data.map((item: FormattedYearlySalesData) => ({
			actualSales: item.actualSales,
			averageOrderValue: item.averageOrderValue,
			year: item.year,
			mostSoldCategory: item.mostSoldCategory,
			mostSoldProduct: item.mostSoldProduct,
			mostSoldProductUnitsSold: item.mostSoldProductUnitsSold,
			orderCount: item.orderCount,
			returnCount: item.returnCount,
			returnRate: item.returnRate,
			totalSales: item.totalSales,
			actualSalesChangePercent: item.actualSalesChangePercent ?? 0,
			orderCountChangePercent: item.orderCountChangePercent ?? 0,
			returnCountChangePercent: item.returnCountChangePercent ?? 0,
			totalSalesChangePercent: item.totalSalesChangePercent ?? 0,
		}));
	}

	async function fetchMonthlyOttoSales(startMonth: string, endMonth: string): Promise<SalesData[] | void> {
		try {
			const token = localStorage.getItem("authToken");
			const response: AxiosResponse<SalesData[]> = await axios.get("/api/otto/analytics/monthly-sales", {
				params: { startMonth, endMonth },
				headers: { Authorization: `Bearer ${token}` },
			});
			const salesData = transformSalesData(response.data);
			setMonthlySales(salesData);
			console.log("Otto Monat", response.data);
			return salesData;
		} catch (error) {
			console.error("Fehler beim Abrufen der monatlichen Verkaufsdaten:", error);
		}
	}

	async function fetchYearlyOttoSales(
		startYear: string,
		endYear: string
	): Promise<FormattedYearlySalesData[] | void> {
		try {
			const token = localStorage.getItem("authToken");
			const response: AxiosResponse<FormattedYearlySalesData[]> = await axios.get(
				"/api/otto/analytics/yearly-sales",
				{
					params: { startYear, endYear },
					headers: { Authorization: `Bearer ${token}` },
				}
			);
			const salesData = transformSalesDataYearly(response.data);
			setYearlySales(salesData);
			console.log("Otto Jahr", response.data);
			return salesData;
		} catch (error) {
			console.error("Fehler beim Abrufen der jährlichen Verkaufsdaten:", error);
		}
	}

	async function fetchTopProducts(limit?: number): Promise<TopSellingProduct[] | void> {
		try {
			const response: AxiosResponse<TopSellingProduct[]> = await axios.get("api/otto/analytics/top-products", {
				headers: { Authorization: `Bearer ${localStorage.getItem("authToken")}` },
				params: { limit },
			});
			setTopProducts(response.data);
			console.log(response.data);
			return response.data;
		} catch (error) {
			console.error("Fehler beim Abrufen der Top-Produkte:", error);
		}
	}

	return (
		<AnalyticsContext.Provider
			value={{
				monthlySales,
				yearlySales,
				topProducts,
				fetchMonthlyOttoSales,
				fetchYearlyOttoSales,
				fetchTopProducts,
			}}
		>
			{children}
		</AnalyticsContext.Provider>
	);
};
