import { effect, signal } from "@preact/signals-react";
import { NavLink, useNavigate } from "react-router-dom";
import { useOrdersShop } from "../../helper/OrderWebshopContext";
import OrderCardWebshop from "../../components/cards/OrderCardWebshop";
import useAuthRequest from "../../../utils/useAuthRequest";
import { getUserRoleFromToken, isTokenExpired } from "../../../utils/auth";
import { useState } from "react";

export default function OrderTable() {
  const [isLoadingPick, setIsLoadingPick] = useState(false);
  const sendRequest = useAuthRequest();
  const navigate = useNavigate();
  const token = localStorage.getItem("authToken");
  const userRole = getUserRoleFromToken();
  const { ordersPickShop, fetchOrdersShop } = useOrdersShop();

  effect(() => {
    if (!token || !userRole || isTokenExpired(token)) {
      navigate("/login");
    }
    fetchOrdersShop("pending");
    const intervalId = setInterval(() => {
      fetchOrdersShop("pending");
    }, 1000);

    // Cleanup-Funktion
    return () => clearInterval(intervalId);
  });

  const handlePackOrderShop = async (orderId: string) => {
    setIsLoadingPick(true);
    try {
      const response = await sendRequest<{ newStatus: string }>(
        `/api/webshop/order/${orderId}/status`,
        "patch",
        { newStatus: "picked" }
      );
      if (response.status === 200) {
        fetchOrdersShop("pending");
        fetchOrdersShop("picked");
        fetchOrdersShop("packed");
        fetchOrdersShop("sent");
      } else {
        throw new Error("Fehler beim Aktualisieren des Bestellstatus");
      }
    } catch (error) {
      console.error(
        "Fehler beim Aktualisieren des Bestellstatus:",
        error
      );
    } finally {
      setIsLoadingPick(false);
    }
  };

  return (
    <>
      <div className="text-gray-900 dark:text-gray-50 flex flex-col gap-4 p-6">
        {ordersPickShop.length > 0 ? (
          ordersPickShop.map((order) => (
            <OrderCardWebshop
              key={order.orderId}
              order={order}
              onPackOrderShop={handlePackOrderShop}
              isLoadingPick={isLoadingPick}
            />
          ))
        ) : (
          <div className="flex flex-col items-start justify-start bg-gray-300 dark:bg-sidebarGray-700 rounded-lg p-6">
            <h1 className="font-bold text-3xl">
              Es wurde keine neuen Bestellungen gefunden.
            </h1>
          </div>
        )}
      </div>
    </>
  );
}
