import { effect, signal } from "@preact/signals-react";
import { NavLink, useNavigate } from "react-router-dom";
import { useOrdersShop } from "../../helper/OrderWebshopContext";
import OrderCardWebshop from "../../components/cards/OrderCardWebshop";
import useAuthRequest from "../../../utils/useAuthRequest";
import { getUserRoleFromToken, isTokenExpired } from "../../../utils/auth";

export default function OrderTableOverview() {
  const sendRequest = useAuthRequest();
  const navigate = useNavigate();
  const token = localStorage.getItem("authToken");
  const userRole = getUserRoleFromToken();
  const { ordersHistoryShop, fetchOrdersShop } = useOrdersShop();

  effect(() => {
    if (
      !token ||
      !userRole ||
      isTokenExpired(token) ||
      userRole !== "bucher"
    ) {
      navigate("/login");
    }
    fetchOrdersShop("sent");
    fetchOrdersShop("canceled");
    const intervalId = setInterval(() => {
      fetchOrdersShop("sent");
      fetchOrdersShop("canceled");
    }, 1000);

    // Cleanup-Funktion
    return () => clearInterval(intervalId);
  });

  const handlePackOrder = async (orderId: string) => {
    try {
      const response = await sendRequest<{ newStatus: string }>(
        `/api/webshop/order/${orderId}/status`,
        "patch",
        { newStatus: "picked" }
      );
      if (response.status === 200) {
        fetchOrdersShop("pending");
        fetchOrdersShop("picked");
        fetchOrdersShop("packed");
        fetchOrdersShop("sent");
        fetchOrdersShop("canceled");
      } else {
        throw new Error("Fehler beim Aktualisieren des Bestellstatus");
      }
    } catch (error) {
      console.error(
        "Fehler beim Aktualisieren des Bestellstatus:",
        error
      );
    }
  };

  return (
    <>
      <div className="text-gray-900 dark:text-gray-50 flex flex-col gap-4 p-6">
        {ordersHistoryShop.length > 0 ? (
          ordersHistoryShop.map((order) => (
            <OrderCardWebshop key={order.orderId} order={order} />
          ))
        ) : (
          <div className="flex flex-col items-start justify-start bg-gray-300 dark:bg-sidebarGray-700 rounded-lg p-6">
            <h1 className="font-bold text-3xl">
              Es wurde keine historischen Bestellungen gefunden.
            </h1>
          </div>
        )}
      </div>
    </>
  );
}
